import { cAxios } from '@/utils/axios.js'

const USERINFO_API_URL = "/api/user/userInfo"

class UserInfoService {

    getUserInfo() {
        return cAxios
            .get(USERINFO_API_URL)
            .then(response => {
                localStorage.setItem('userInfo', JSON.stringify(response.data))
                return response.data;

            }).catch(error => {
                console.log(error.response);
            })
    }

}
export default new UserInfoService();