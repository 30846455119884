<template>
	<div class="card-header">
		<!-- header title -->
		<h3>{{ headerTitle }}</h3>
		<!-- //header title -->

		<!-- select box -->
		<!-- <div class="credit-sel" v-if="isSelect">
			<label class="hidden">{{ labelText }}</label>
			<select class="form-control">
				<option v-for="(item, index) in options" :key="index">{{ item }}</option>
			</select>
		</div> -->
		<!-- //select box -->

		<slot name="slot_header_btns"/>
				
	</div>
</template>
<script>

export default {
	name: 'CardHeader',

	props: {
		headerTitle: {
			type: String,
			required: true,
			default: '',
		},
		isSelect: {
			type: Boolean,
			required: false,
			default: false,
		},
		labelText: {
			type: String,
			required: false,
			default: '',
		},
		options: {
			type: Array,
			required: false,
			default: undefined,
		},
	},
	
}
</script>