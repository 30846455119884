<template>
	<div class="box-footer clearfix tx_cnt">
		<ul class="pagination justify-content-center">
			<li v-if="prev" class="page-item">
				<a href="#" class="page-link" aria-label="Previous" @click="movePage((startPageIndex - 1),((startPageIndex -1) * listRowCount))">
					<span aria-hidden="true">&laquo;</span>
				</a>
			</li>
			<li v-for="index in endPageIndex-startPageIndex+1" :key="index" class="page-item" :class="{active:( (startPageIndex + index - 1) == currentPageIndex)}">
				<a href="#" class="page-link" @click="movePage((startPageIndex + index - 1), ((startPageIndex + index - 1) * listRowCount))">
					{{ startPageIndex + index - 1 }}
				</a>
			</li>
			<li v-if="next" class="page-item">
				<a href="#" class="page-link" aria-label="Next" @click="movePage((endPageIndex + 1), ((endPageIndex + 1) * listRowCount))">
					<span aria-hidden="true">&raquo;</span>
				</a>
			</li>
		</ul>
	</div>
</template>
<script>

export default {
	name: 'Pagination',

	props: [
		'prefix',
		'pTotalListItemCount',
		'pageInit' //검색조건 변경등에 의해 재조회 할 경우 현재페이지 초기화
	],
	data: () => ({
		listRowCount : 10,
		pageLinkCount: 10,
		currentPageIndex : 1,
 
		pageCount : 0,
		startPageIndex : 1,
		endPageIndex : 1,
		prev : false,
		next : false,
	}),
	computed:{
		totalListItemCount(){
			return this.pTotalListItemCount;
		}
	},
	watch:{
		totalListItemCount(){
			this.initUI();
		},
		currentPageIndex(){
			this.initUI();
		},
		pageInit(val){
			if(val){
				this.currentPageIndex = 1;
			}
		}
	},
	methods: {
		movePage( currPage , pageIndex) {
			if(currPage != 0){	// currPage가 있는 경우에만 페이징 인덱스값 계산하자.
				this.currentPageIndex = currPage;
				this.$emit("toParent:changePage", pageIndex, currPage); //조회할 리스트의 인덱스값 , 현재페이지
			}
		},
		initUI(){
			//총 출력 페이지
			this.pageCount = Math.ceil(this.totalListItemCount/this.listRowCount);

			//페이지네이션 시작페이지 set
			if((this.currentPageIndex % this.pageLinkCount) == 0 ){
				this.startPageIndex = ((this.currentPageIndex / this.pageLinkCount) - 1) * this.pageLinkCount + 1
			}else{
				this.startPageIndex = Math.floor(this.currentPageIndex / this.pageLinkCount) * this.pageLinkCount + 1
			}

			//페이지네이션 마지막페이지 set
			if((this.currentPageIndex % this.pageLinkCount) == 0){
				this.endPageIndex = ((this.currentPageIndex / this.pageLinkCount) - 1) * this.pageLinkCount + this.pageLinkCount
			}else{
				this.endPageIndex =  Math.floor(this.currentPageIndex / this.pageLinkCount) * this.pageLinkCount + this.pageLinkCount;
			}

			if(this.endPageIndex > this.pageCount){
				this.endPageIndex = this.pageCount
			}

			//현 페이지가 페이지네이션에 표시될 링크수보다 적을경우 이전버튼 hidden
			if( this.currentPageIndex <= this.pageLinkCount ){
				this.prev = false;
			}else{
				this.prev = true;
			}

			//마지막 페이지가 총 페이지수 보다 크거나 같을경우 다음버튼 hidden
			if(this.endPageIndex >= this.pageCount){
				this.endPageIndex = this.pageCount;
				this.next = false;
			}else{
				this.next = true;
			}

		}
	},
	mounted(){
		this.$emit("toParent:changePage", this.listRowCount, 1);
	}
}
</script>