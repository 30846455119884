<template>
	<select class="$attrs.selectBoxClass" :id="$attrs.id" @change="onChange($event.target.value)">
		<option v-for="(item) in $attrs.items" :key="item.key" 
			v-bind:value="item.key" :selected="item.selected" >
			{{ item.value }}
		</option>
	</select>
</template>
<script>

export default {
	name: 'SelectBox',

	methods: {
		onChange(value) {
			this.$emit('toParent:selectValue', value)
			this.$emit('toParnet:setValue',value);
		}
	},
}
</script>