<template>
  <div>
    <label class="tx_rt ml20 mr10 bold" v-if="$attrs.isDateGubun == false"><i class="fas fa-caret-right fs14"></i> 조회기간 설정</label>

    <date-picker ref="fromDate" class="w200" v-model="fromDate" :editable="false" type="month" value-type="format" placeholder="2020-00" width="500" @change="changeFromDate($event)"></date-picker>
    <span class="datepicker-span"> ~ </span>
    <date-picker ref="toDate" class="w200" v-model="toDate" :editable="false" type="month" value-type="format" placeholder="2020-00" width="500" @change="changeToDate($event)"></date-picker>

    <!-- 검색영역 안에서 조회버튼 넣어야하는 row면 넣기 -->
    <slot name="slot_searchBtn" />
  </div>

</template>

<script>
export default {
  name: "BaseCalendarSearchRowForYearMonth",

  data: () => ({
    selectBoxClass: 'form-control pl5 form-control-inline w150 mr5',
    selectBoxItems: [
      {key: '', value: '선택',},
      {key: 'inqDate', value: '조회일자 기준',},
      {key: 'sendDate', value: '시행일자 기준',},
    ],

    fromDate: '',
    toDate: '',
  }),
  methods : {
    changeDateGubun(date) {
      this.$emit('toParent:selectDateGubun', date);
    },
    changeFromDate(date) {
      this.$emit('toParent:setFromDate', date);
    },
    changeToDate(date) {
      this.$emit('toParent:setToDate', date);
    },
  },
  mounted() {
    //현월 ~ 현월
    const current = new Date();

    let year = current.getFullYear();
    let month = (1 + current.getMonth());
    month = month >= 10 ? month : '0' + month;	//month 두자리로

    var currDate = year + '-' + month;

    this.fromDate = currDate;
    this.toDate = currDate;

    this.$emit('toParent:setFromDate', this.fromDate);
    this.$emit('toParent:setToDate', this.toDate);
  }

}
</script>

<style scoped>

</style>