<template>
	<div class="w3-modal w3-animate-opacity" v-if="isVisible" style="display:block">
		<div class="w3-modal-content w3-card-4 w3-animate-zoom pt20 pb30 pr20 pl25"
			 style="max-width:800px;height:650px;">
			<div class="w3-center">
				<span @click="closePopup()" class="w3-button w3-xlarge w3-transparent w3-display-topright fs30 bold lh25" title="Close Modal">×</span>
			</div>
			<h4 class="login-pop-h4 tx_cnt">계정 변경</h4>
			<!-- radio 영역 -->
			<div class="w3-center mt20 mb20 fs17" style="">
				<label class="pr50">
					<input type="radio" v-model="typeRadio" value="CORP" style="width: 16px;height: 16px;" /> 사업자
				</label>
				<label class="pl50">
					<input type="radio" v-model="typeRadio" value="PERSON" style="width: 16px;height: 16px;" />개인
				</label>
				<label class="pl50" v-if="role == 'CLIENT_MANAGER'">
					<input type="radio" v-model="typeRadio" value="AGENT" style="width: 16px;height: 16px;" />세무대리
				</label>
			</div>
			<!-- 검색영역 -->
			<div class="credit-table-block pl20 pt20 pb20 mb20" style="text-align:left;">
				<!-- 1 row -->
				<div class="tx_lt pb5 pt5" v-if="typeRadio == 'CORP'">
					<!-- 상호 -->
					<label class="w100 bold"><i class="fas fa-caret-right fs14 mr5"></i>상호</label>
					<input class="w150 mr20" type="text" placeholder="상호 입력" v-model="enteredCorpName" />
					<!-- 사업자 번호 -->
					<label class="w100 bold"><i class="fas fa-caret-right fs14 mr5"></i>사업자번호</label>
					<input class="w150" type="text" placeholder="사업자번호" v-model="enteredCorpRegNo"/>

					<div class="btnwrap-right">
						<button type="button" class="btn btn-darkgrey ml15 mr20 w120" @click="searchCompanyOrAgent(typeRadio)">조회</button>
					</div>
				</div>
				<div class="tx_lt pb5 pt5"  v-if="typeRadio == 'PERSON'">
					<!-- 대표명 -->
					<label class="w100 bold"><i class="fas fa-caret-right fs14 mr5"></i>이름</label>
					<input class="w150 mr20" type="text" placeholder="이름 입력" v-model="enteredReprName" />
					<!-- 사업자 번호 -->
					<label class="w100 bold"><i class="fas fa-caret-right fs14 mr5"></i>생년월일</label>
					<input class="w150" type="text" placeholder="생년월일" v-model="enteredBirthday" />

					<div class="btnwrap-right">
						<button type="button" class="btn btn-darkgrey ml15 mr20 w120" @click="searchCompanyOrAgent(typeRadio)">조회</button>
					</div>
				</div>
				<div class="tx_lt pb5 pt5" v-if="typeRadio == 'AGENT'">
					<!-- 세무대리직원? -->
					<label class="w100 bold"><i class="fas fa-caret-right fs14 mr5"></i>직원아이디</label>
					<input class="w150 mr20" type="text" placeholder="직원아이디" v-model="enteredTaxAgentEmployee" />
					<!-- 세무대리인명 -->
					<label class="w100 bold"><i class="fas fa-caret-right fs14 mr5"></i>세무대리인명</label>
					<input class="w150 mr20" type="text" placeholder="세무대리인명" v-model="enteredTaxAgentName" />

					<div class="btnwrap-right">
						<button type="button" class="btn btn-darkgrey ml15 mr20 w120" @click="searchCompanyOrAgent(typeRadio)">조회</button>
					</div>
				</div>
			</div>
			<div class="w3-section mr10 ml15" style="height:60%;overflow:auto;">
				<!-- radio : 사업자 -->
				<table class="table00" v-if="typeRadio == 'CORP'" style="border-collapse:collapse;">
					<colgroup>
						<col style="width:30%">
						<col>
						<col style="width:20%">
					</colgroup>
					<thead>
					<tr>
						<th style="position:sticky;top:0px;">상호</th>
						<th style="position:sticky;top:0px;">사업자번호</th>
						<th style="position:sticky;top:0px;">선택</th>
					</tr>
					</thead>
					<tbody v-if="typeRadio == 'CORP'  && corpTableItems.length != 0">
					<tr v-for="(item, index) in corpTableItems" :key="index">
						<td class="tx_cnt">{{ item.corpName }}</td>
						<td class="tx_cnt">{{ item.corpRegNo }}</td>
						<td class="tx_cnt">
							<i class="fas fa-check-circle fs14 mr5 text-orange" v-if="isCompanyInfoNotNull(currCompanyIdx) && isCurrCompany(currCompanyIdx, item.idx)"></i>
							<button class="btn btn-default pr10 pl10 mr5 txt-white"
									style="background-color:#414960;font-size:10pt;"
									@click="changeMemberCompanyOrClientCompany(item.idx)" v-else>선택</button>
						</td>
					</tr>
					</tbody>
					<tbody v-if="typeRadio == 'CORP'  && corpTableItems.length == 0">
					<tr>
						<td colspan="3" class="tx_cnt">조회결과가 없습니다.</td>
					</tr>
					</tbody>
				</table>
				<!-- radio : 개인 -->
				<table class="table00" style="border-collapse:collapse;" v-else-if="typeRadio == 'PERSON'" >
					<colgroup>
						<col style="width:30%">
						<col>
						<col style="width:20%">
					</colgroup>
					<thead>
					<tr>
						<th style="position:sticky;top:0px;">이름</th>
						<th style="position:sticky;top:0px;">주민번호</th>
						<th style="position:sticky;top:0px;">선택</th>
					</tr>
					</thead>
					<tbody v-if="typeRadio == 'PERSON'  && personTableItems.length != 0">
					<tr v-for="(item, index) in personTableItems" :key="index">
						<td class="tx_cnt">{{ item.reprName }}</td>
						<td class="tx_cnt">{{ item.resNo }}</td>
						<td class="tx_cnt">
							<i class="fas fa-check-circle fs14 mr5 text-orange" v-if="isCompanyInfoNotNull(currCompanyIdx) && isCurrCompany(currCompanyIdx, item.idx)"></i>
							<button class="btn btn-default pr10 pl10 mr5 txt-white"
									style="background-color:#414960;font-size:10pt;"
									@click="changeMemberCompanyOrClientCompany(item.idx)" v-else>선택</button>
						</td>
					</tr>
					</tbody>
					<tbody v-if="typeRadio == 'PERSON'  && personTableItems.length == 0">
					<tr>
						<td colspan="3" class="tx_cnt">조회결과가 없습니다.</td>
					</tr>
					</tbody>
				</table>
				<!-- radio : 세무대리인 -->
				<table class="table00" style="border-collapse:collapse;" v-else>
					<colgroup>
						<col style="width:30%">
						<col>
						<col style="width:20%">
					</colgroup>
					<thead>
					<tr>
						<th style="position:sticky;top:0px;">직원아이디</th>
						<th style="position:sticky;top:0px;">세무대리인명</th>
						<th style="position:sticky;top:0px;">선택</th>
					</tr>
					</thead>
					<tbody v-if="typeRadio == 'AGENT'  && agentTableItems.length != 0">
					<tr v-for="(item, index) in agentTableItems" :key="index">
						<td class="tx_cnt">{{ item.clientMemberId }}</td>
						<td class="tx_cnt">{{ item.agentName }}</td>
						<td class="tx_cnt">
							<i class="fas fa-check-circle fs14 mr5 text-orange" v-if="isCompanyInfoNotNull(currAgentMemberIdx) && isCurrCompany(currAgentMemberIdx, item.idx)"></i>
							<button class="btn btn-default pr10 pl10 mr5 txt-white"
									style="background-color:#414960;font-size:10pt;"
									@click="changeClientAgent(item.idx)" v-else>선택</button>
						</td>
					</tr>
					</tbody>
					<tbody v-if="typeRadio == 'AGENT'  && agentTableItems.length == 0">
					<tr>
						<td colspan="3" class="tx_cnt">조회결과가 없습니다.</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: "BaseUserCompanyChangePopup",

	props: {
		isVisible: {
			type: Boolean,
			required: true,
		},
		role: {
			type: String,
			required: true,
		}
	},
	data : () => ({

		//Variable
		currCompanyIdx: '',
    currAgentMemberIdx: '',
		enteredCorpRegNo: '',
		enteredBirthday: '',
		enteredCorpName: '',
		enteredReprName: '',
		enteredTaxAgentEmployee: '',
		enteredTaxAgentName: '',
		jsonHeader: {
			headers: {
				'Content-Type': 'application/json;'
			}
		},

		//Component attrs
		typeRadio: null,
		corpTableItems: [],
		personTableItems: [],
		agentTableItems: [],
	}),
	computed: {
		...mapState(['user']),
	},
	methods: {
		closePopup() {
			//	초기화
			this.typeRadio = null;

			this.$emit('toParent:isVisiblePopup', false);
		},
		//  1. 사업장 조회 : user의 corp/person 사업장
		getUserCompanyList(type) {
			this.getCorpPersonCompanyList('api/user/companiesInfo', type);
		},
		//	2-1. 사업장 조회 : client_manager의 corp/person 사업장
		getClientCompanyList(type) {
			this.getCorpPersonCompanyList('api/client/companiesInfo', type);
		},
		//	2-2. 세무대리인 조회 : client_manager의 agent
		getClientAgentList(type) {
			if (type == 'AGENT') {
				//	그리드 초기화
				this.agentTableItems = [];

				//	검색영역 값 세팅
				let params = {};
				params = {
					clientMemberId: this.enteredTaxAgentEmployee,
					agentName: this.enteredTaxAgentName
				}

				this.$cAxios.post('api/client/agentsInfo', params, this.jsonHeader)
					.then(response => {
						if (response.data.AGENTS.length > 0) {

							this.agentTableItems = response.data.AGENTS;
						}
					}).catch(error => {
					console.log(error.response.data.message);
				});
			}
		},
		//	user & client의 CORP/PERSON 조회 ajax 호출 메서드
		getCorpPersonCompanyList(url, type) {
			//	그리드 초기화
			this.corpTableItems = [];
			this.personTableItems = [];

			//	검색영역 값 세팅
			let params = {};
			if (type == 'CORP') {
				params = {
					corpRegNo: this.enteredCorpRegNo,
					corpName: this.enteredCorpName,
					bizKind: type	// CORP
				};
			} else {
				params = {
					birthday: this.enteredBirthday,
					reprName: this.enteredReprName,
					bizKind: type	// PERSON
				};
			}

			this.$cAxios.post(url, params, this.jsonHeader)
				.then(response => {
					if (response.data.COMPANIES.length > 0) {
						// console.dir(response.data.COMPANIES);

						for (var i = 0; i < response.data.COMPANIES.length; i++) {
							//row별 사업자번호 format
							let corpRegNo = response.data.COMPANIES[i].corpRegNo;

							if (type == 'CORP') {
								// 사업자번호 - format
								response.data.COMPANIES[i].corpRegNo = this.formatCorpRegNo(corpRegNo);
							}
						}
						//radio에 따른 table items 세팅
						if (type == 'CORP') {

							this.corpTableItems = response.data.COMPANIES;
						} else {

							this.personTableItems = response.data.COMPANIES;
						}
					}

				}).catch(error => {
				console.log(error.response.data.message);
			})
		},
		//  3-1. user & client_manager : CORP/PERSON 사업장 변경
		changeMemberCompanyOrClientCompany(val) {
			if (confirm('계정을 선택한 사업장으로 변경하시겠습니까?')) {
				this.$cAxios.post('api/user/updateCurrCompany', 'companyIdx=' + val)
					.then((response) => {
						// localStorage & store의 userInfo(사업장 관련정보) 재정의
						this.$store.dispatch('user/getUserInfo')
							.then(() => {
								alert('선택 사업장을 변경했습니다.');

								//사업자 변경 여부 parent로 전달
								this.$emit('toParent:isUpdateCompany', true);

								// 팝업 닫기
								this.closePopup();
							})
					}).catch((error) => {
					console.log(error.response.data.message);
				})
			}
		},
		//	3-2. client_manager : agent 변경
		changeClientAgent(val) {
			if (confirm('계정을 선택한 세무대리인으로 변경하시겠습니까?')) {
				this.$cAxios.post('api/user/updateCurrCompany', 'memberIdx='+val)
					.then((response) => {
						// localStorage & store의 agentInfo(세무대리인 관련정보) 재정의
						this.$store.dispatch('user/getAgentInfo')
							.then(() => {
								alert('선택 사업장을 변경했습니다.');

								//사업자 변경 여부 parent로 전달
								this.$emit('toParent:isUpdateCompany', true);

								// 팝업 닫기
								this.closePopup();
							})
					}).catch((error) => {
					console.log(error.response.data.message);
				})
			}
		},
		//  4. 사업장 검색
		searchCompanyOrAgent(type) {
			// 사업장 조회 2가지
			if (this.role != 'CLIENT_MANAGER') {
				// 1. user
				this.getUserCompanyList(type);
			} else {
				// 2. client_manager
				if (type == 'AGENT') {
					this.getClientAgentList(type);
				} else {
					this.getClientCompanyList(type);
				}
			}
		},
		isCompanyInfoNotNull(currIdx) {
			if (currIdx != null && currIdx != undefined && currIdx != '') {
				return true;
			}
		},
		isCurrCompany(currChooseIdx, rowIdx) {
			return currChooseIdx == rowIdx;
		},
		// 사업자번호 format
		formatCorpRegNo(str) {
			let originStr = str.toString();
			let formattedStr = '';

			if (originStr != null || originStr != '') {
				if (originStr.length == 10) {
					formattedStr = originStr.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
				}
			}
			return formattedStr;
		},
	},
	watch: {
		isVisible(val) {
			if (val) {
				// localStorage의 현재사업장 idx 킵
				this.currCompanyIdx = this.user.userInfo.CLIENT_MEMBER_COMPANY_IDX;
				this.currAgentMemberIdx = this.user.userInfo.CLIENT_MEMBER_IDX;

				//그리드 초기화
				this.corpTableItems = [];
				this.personTableItems = [];
				this.agentTableItems = [];

				//그리드 items 초기 조회
				this.typeRadio = 'CORP';
			}
		},
		typeRadio(val) {
			if (val != null && val != '' && val != undefined) {
				// 검색영역 값 초기화
				this.enteredCorpRegNo = '';
				this.enteredCorpName = '';
				this.enteredBirthday = '';
				this.enteredReprName = '';

				// 사업장 조회 2가지
				if (this.role != 'CLIENT_MANAGER') {
					// 1. user
					this.getUserCompanyList(val);
				} else {
					// 2. client_manager
					if (val == 'AGENT') {
						this.getClientAgentList(val);
					} else {
						this.getClientCompanyList(val);
					}
				}
			}
		},
	}
}
</script>

<style scoped>

</style>