<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  
}
</script>
<style lang="scss">
@import './css/w3.css';
@import './css/common.css';
@import '~vue2-datepicker/scss/index.scss';
@import './css/basic.css';
</style>
