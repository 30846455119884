<template>
	<div class="w3-modal w3-animate-opacity" v-if="$attrs.visible" style="display:block">
		<div class="w3-modal-content w3-card-4 w3-animate-zoom pt20 pb30 pr20 pl25" style="max-width:700px">
			<div class="w3-center">
				<span @click="closePopup" class="w3-button w3-xlarge w3-transparent w3-display-topright fs30 bold lh25" title="Close Modal">×</span>
			</div>
			<validation-observer ref="form" v-slot="{handleSubmit}">
			<form class="form-group" action="">
				<h4 class="login-pop-h4 tx_cnt">{{ $attrs.popupTitle }}</h4>
				<div class="w3-section mr10 ml15">
					<!-- 간편 카드 등록 -->
					<table class="table03">
						<caption>간편 등록 표</caption>
						<colgroup>
							<col style="width:150px">
							<col>
						</colgroup>
						<tr>
							<th class="tx_cnt"><label for="">대상</label></th>
							<td class="tx_lf pl15">
								<label for="" class="w80"><input type="radio" name="bizKind" id="person" value="PERSON" v-model="pickedBizKind"> 개인</label>
								<label  for="" class="w80"><input type="radio" name="bizKind" id="corp" value="CORP" v-model="pickedBizKind"> 법인</label>
							</td>
						</tr>
						<tr>
							<th class="tx_cnt">
								<label v-if="financeType == 'BANK'" for="">은행사</label>
								<label v-else for="">카드사</label>
							</th>
							<td class="tx_lf pl15">
								<base-select-box
									:class="selectBoxClass1"
									:id="'selectBoxFinance'"
									:items="financeCompanyList" 
									@toParent:selectValue="val => selectedFinCompany(val)"
									/>
							</td>
						</tr>						
						<tr>
							<th class="tx_cnt"><label for="">인증수단</label></th>
							<td class="tx_lf pl15">
								<label v-if="supportCert" for="" class="w80"><input type="radio" name="authKind" id="cert" value="CERT" v-model="pickedAuthKind"> 인증서</label>
								<label v-if="supportLogin" for="" class="w80"><input type="radio" name="authKind" id="idpw" value="ID" v-model="pickedAuthKind"> ID/PW</label>
								<label v-if="noSupportAuth" for="">{{noSupportAuthMsg}}</label>
							</td>
						</tr>
						<tr v-if="pickedAuthKind != '' ">
							<th class="tx_cnt"><label for="">인증정보</label></th>
							<td class="tx_lf pl15">								
								
								<!-- options 세팅해서 base select box에 넘겨주기  -->
								<base-select-box 
									:class="selectBoxClass2"
									:items="authList"
									:selected="selectedAuth"
									@toParent:selectValue="val => selfFillIn(val)"/>
								<!-- <select class="form-control-inline w250">
									<option>선택</option>
									<option>직접입력</option>
									<option>honggildong</option>
								</select> -->
							</td>
						</tr>
						<tr v-if="pickedAuthKind == 'CERT' && selfFillInYN == true">
							<th class="tx_cnt"><label for="">인증서명</label></th>
							<td class="tx_lf pl15">
								<validation-provider vid="certNm" rules="required" v-slot="{errors}">
									<span class="displaylinebl"><input class="form-control form-control-table w250" type="text" style="border:none; border-color:transparent;" v-model="certNm" disabled/></span>
									<button type="button" @click="loadDoc('setup', null)" class="btn btn-block btn-default pt5 pb4 btn-sm ml5">인증서 등록</button>
									<p v-if="errors[0]" class="help-block txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</p>
								</validation-provider>
							</td>
						</tr>
						<tr v-if="pickedAuthKind == 'ID' && selfFillInYN == true">
							<th class="tx_cnt"><label for="">웹 아이디</label></th>
							<td class="tx_lf pl15">
								<validation-provider vid="siteId" rules="required" v-slot="{errors}">
									<input class="form-control form-control-table w250" type="text" v-model="siteId" placeholder="아이디를 입력해주세요">
									<p v-if="errors[0]" class="help-block txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</p> 
								</validation-provider>
							</td>
						</tr>
						<tr v-if="pickedAuthKind == 'ID' && selfFillInYN == true">
							<th class="tx_cnt"><label for="">웹 패스워드</label></th>
							<td class="tx_lf pl15">
								<validation-provider vid="sitePwd" rules="required" v-slot="{errors}">
									<input class="form-control form-control-table w250" type="password" v-model="sitePwd" placeholder="비밀번호를 입력해주세요">
									<p v-if="errors[0]" class="help-block txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</p>
								</validation-provider>
							</td>
						</tr>
						<tr v-if="pickedAuthKind != '' && financeType == 'BANK'">
							<th class="tx_cnt"><label for="">계좌번호</label></th>
							<td class="tx_lf pl15">
								<validation-provider vid="accountNo" rules="required" v-slot="{errors}">
									<input class="form-control form-control-table w250" maxlength="15" type="text" v-model="accountNo" @input="accountNoHasOnlyNumbers" placeholder="계좌번호를 입력해주세요">
									<p v-if="errors[0]" class="help-block txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</p>
								</validation-provider>
							</td>
						</tr>
						<tr v-if="pickedAuthKind != '' && financeType == 'CARD'">
							<th class="tx_cnt"><label for="">카드번호</label></th>
							<td class="tx_lf pl15">
								<validation-provider vid="cardNo" rules="required" v-slot="{errors}">
									<input class="form-control form-control-table w250" type="text" maxlength="16" v-model="cardNo" @input="cardNoHasOnlyNumbers" placeholder="카드번호를 입력해주세요">
									<p v-if="errors[0]" class="help-block txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</p>
                </validation-provider>
							</td>
						</tr>
						<tr v-if="pickedAuthKind != '' ">
							<th class="tx_cnt"><label for="">별칭</label></th>
							<td class="tx_lf pl15">
								<validation-provider vid="financeAlias" rules="required" v-slot="{errors}">
									<input class="form-control form-control-table w140" type="text" v-model="financeAlias" placeholder="별칭을 입력해주세요">
									<p v-if="errors[0]" class="help-block txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</p>
								</validation-provider>
							</td>
						</tr>
						
					</table>
					<!-- //간편 카드 등록 -->
					<!-- Error message -->
					<span v-if="errorMsg != ''" class="error-message tx_cnt"><i class="fa fa-times-circle-o has-error"></i> {{errorMsg}}</span>
					<!-- Button -->
					<div v-if="financeType == 'CARD'" class="btnwrap clear pt10">
						<button v-if="pickedAuthKind == 'CERT'" @click="handleSubmit(registCardByCertAuth())" type="button" class="btn btn-block btn-primary w115">등록</button>
						<button v-if="pickedAuthKind == 'ID'" @click="handleSubmit(registCardByIdpwAuth())" type="button" class="btn btn-block btn-primary w115">등록</button>
						<button @click="closePopup" type="button" class="btn btn-block btn-default mr10 ml5 w115">취소</button>
					</div>
					<div v-if="financeType == 'BANK'" class="btnwrap clear pt10">
						<button v-if="pickedAuthKind == 'CERT'" @click="handleSubmit(registAccountByCertAuth())" type="button" class="btn btn-block btn-primary w115">등록</button>
						<button v-if="pickedAuthKind == 'ID'" @click="handleSubmit(registAccountByIdpwAuth())" type="button" class="btn btn-block btn-primary w115">등록</button>
						<button @click="closePopup" type="button" class="btn btn-block btn-default mr10 ml5 w115">취소</button>
					</div>
				</div>
			</form>
			</validation-observer>

      <div class="w3-container">
        <base-card-select
            v-if="isVisibleCardSelect"
            :authId="siteId"
            :authPwd="sitePwd"
            :authIdx="authIdx"
            :cardNo="cardNo"
            @close="(s) => isVisibleCardSelect = s"
            @selectItem="(item) => placeCardNumber(item)"/>
      </div>
		</div>
	</div>
</template>
<script>
import { mapState } from 'vuex'

export default {
	name: 'SimpleRegPopup',
	
	data: () => ({
		prefix: "",
		selectBoxClass1: 'form-control-inline w120',
		selectBoxClass2: 'form-control-inline w250',
		
		pickedBizKind: "PERSON",
		pickedAuthKind: "ID",

		//auth_select_box
		selectedAuth :"",

		//select_box_data_list
		financeCompanyList:[],
		authList:[],
		supportLogin : false,
		supportCert : false,
		noSupportAuth : false,
		noSupportAuthMsg : "선택가능한 인증수단이 없습니다.",

		//cert_regist_attr
		sendData:{},
		executeData:{},
		certNm:"",

		//idpw_regist_attr
		siteId:"",
		sitePwd:"",

		//finance_regist_attr
		authIdx:"",
		cardNo:"",
		accountNo:"",
		financeAlias:"",
    cdNo:"",

    //error
		errorMsg:"",

    //popup control
    isVisibleCardSelect: false,

		selfFillInYN: true,
	}),
	props:['init','financeType'],
	watch:{
		init(val){
			if(val) this.initInfo();
		},
		pickedBizKind(){
			this.getFinanceCompanyList(); 
		},
		pickedAuthKind(val){
			if(val == '') {
				return ;
			}
			this.authListInquriy();

			this.sendData = {};
			this.executeData = {};
			this.certNm = "";

			this.siteId = "";
			this.sitePwd = "";
			this.$refs.form.reset();
		},
		authList(){
			this.siteId = "";
			this.sitePwd = "";
		},
	},
  computed: {
    ...mapState([
      'user'
    ])
  },
	methods: {
		initInfo(){
			if(this.financeType == 'BANK'){
				this.prefix = "api/auth/bank"; 
			}else{
				this.prefix = "api/auth/card";
			}

			this.getFinanceCompanyList();
		},
		cardNoHasOnlyNumbers: function(){
			this.cardNo = this.cardNo.replace(/[^0-9.]/g,'');
		},
		accountNoHasOnlyNumbers: function(){
			this.accountNo = this.accountNo.replace(/[^0-9.]/g,'');
		},
		getFinanceCompanyList(){
			let scrapSiteInfo = new Object();
			scrapSiteInfo.bizKind = this.pickedBizKind;

			this.$cAxios.post(this.prefix + "/finance/company/list/inquriy",JSON.stringify(scrapSiteInfo),{
				headers:{
					'Content-Type':'application/json'
				}
			})
			.then((res)=>{
				if(res.data != null){
					this.financeCompanyList = [];
					this.financeCompanyList.push({
						key: '',
						value:"선택",
						supportLogin: false,
						supportCert: false,
					});

					for(var i = 0 ; i < res.data.length; i++){
						var resData = {
							key : res.data[i].idx,
							value : res.data[i].name,
							supportLogin : res.data[i].supportLogin,
							supportCert : res.data[i].supportCert
						};
						this.financeCompanyList.push(resData);
					}
					this.pickedAuthKind = "";
					this.supportLogin = false;
					this.supportCert = false;
				}
				
			}).catch((err)=>{
				console.log(err.response);
			}).finally(()=>{
			});
			
		},
		authListInquriy(){
			let authInfo = new Object();

			let FinanceGbnComp =  document.getElementById("selectBoxFinance");
			if(FinanceGbnComp.selectedIndex == 0) return false;
			var FinanceGbn = FinanceGbnComp.options[FinanceGbnComp.selectedIndex].value;
			authInfo = {};
			authInfo.idx = FinanceGbn;
			authInfo.authType = this.pickedAuthKind;
			this.$cAxios.post(this.prefix + "/auth/list/inquriy", JSON.stringify(authInfo),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then((res)=>{
				if(res.data != null){
					this.authList = [];

					this.authList.push({key:"", value:"직접입력"});
					let resData = new Object;
					for(var i= 0 ; i< res.data.length; i++){
						resData = {};
						resData.key = res.data[i].idx;
						resData.value = (res.data[i].authType == 'CERT'? res.data[i].certNm : res.data[i].authId);
						
						let selected = "";
						if(this.selectedAuth != "" && res.data[i].idx == this.selectedAuth){
							selected = this.selectedAuth ;
						}
						resData.selected = selected;
						this.authList.push(resData);
					}
				}
				
				this.selfFillInYN = true;
			}).catch(()=>{
				alert("인증정보 조회중 문제가 발생했습니다.");
			});
			
		},
		selectedFinCompany(val){
      // 초기화
      this.cardNo = '';

			if(val == '') {
				this.pickedAuthKind = ''; //인증수단
				this.noSupportAuth = false;
				this.supportLogin = false;
				this.supportCert = false;
				return false;
			}
			
			this.financeCompanyList.filter((comp) => {
				if(comp.key == val) {
					if(comp.supportCert == true && this.financeType == 'BANK' && this.user.userInfo.CLIENT_BANK_CERT_ENABLE == "true"){
						this.supportCert = true;
					} else if (comp.supportCert == true && this.financeType == 'CARD' && this.user.userInfo.CLIENT_CARD_CERT_ENABLE == "true"){
						this.supportCert = true;
					}

					this.supportLogin = comp.supportLogin;
					if(this.supportCert == true){
						this.pickedAuthKind = "CERT";
						this.noSupportAuth = false;
					}else if(this.supportLogin == true){
						this.pickedAuthKind = "ID";
						this.noSupportAuth = false;
					}else {
						this.pickedAuthKind = '';
						this.noSupportAuth = true;
					}

				}
        if(this.pickedAuthKind != ''){
          this.authListInquriy();
        }else{
          //금융사를 선택했으나 사용자 설정 & 카드사의 인증허용수단에 의해
          //선택할 수 있는 인증정보가 없는경우
          this.initFinanceInfo();
        }
			});



		},
		loadDoc (s_op, s_inJson) {
			let loadNxClient = (v_s_op, v_s_inJson) => {
				let sUrl = "https://127.0.0.1:16565/?op=";
				if(v_s_op == 'certSelect'){
					sUrl = "https://127.0.0.1:16566/?op=";
				}

				this.$cAxios.post(sUrl+v_s_op, v_s_inJson ,{
					crossDomain: true,
					headers:{
						'Content-type':'application/json; charset=UTF-8',
					},
				}).then((res) =>{
					result(v_s_op, res.data);
				}).catch((err) => {
					if(err.request){ 
						if(err.config.url.indexOf('?op=setup') != -1){
							alert("공인인증서 등록 프로그램 설치가 필요합니다.");
							fnNxDownload();
						}
					}

				}).finally(() => {
				});

			}

			//첫 로드
			loadNxClient(s_op, s_inJson);
			let result = (s_op, data) => {
				var errYn = data.errYn;
				if('setup' == s_op){
					if (errYn == 'N') {
						//certImageUrl : 배너이미지
						loadNxClient('certSelect', '{"certImageUrl": "", "nxKeypad": ""}');
					}
				} else if ('certSelect' == s_op) {
					//init
					this.sendData = {};
					this.executeData = {};
					this.certNm = "";

					//인증서 선택
					this.sendData.certNm = data.cert_nm; //인증서명
					this.sendData.certPubDate = data.pub_dt;
					this.sendData.certEndDate = data.end_dt;
					this.sendData.certOrgNm = data.org_nm;//인증기관명 (yessign 등...)
					this.sendData.certPolicyId = data.oid;
					this.sendData.certSerialNo = data.sn;
					this.sendData.password = data.cert_pw;

					this.certNm = data.cert_nm;

					this.executeData.file1 = data.file1;
					this.executeData.file2 = data.file2;
					this.executeData.cert_pw = data.cert_pw;
					
					this.$refs.form.setErrors({
						certNm:[],
					})
				} 

			}

			let fnNxDownload = () => {
				location.href = "/files/ExAdapter_Web_Setup_20200611.exe";
			}

		},
		certAuthRegist(){

			if(Object.keys(this.sendData).length === 0 && this.sendData.constructor === Object) {
				alert("인증서를 선택해주세요.");
				return false;
			}

			let loadNxClient = (v_s_op, v_s_inJson) => {
				let sUrl = "https://127.0.0.1:16565/?op=";
				this.$cAxios.post(sUrl+v_s_op, v_s_inJson ,{
					crossDomain: true,
					headers:{
						'Content-type':'application/json; charset=UTF-8',
					},
				}).then((res) =>{
					result(v_s_op, res.data);
				}).catch((err) => {
					if(err.request){ 
						console.log(err.request);
					}
				}).finally(() => {
				});

			}

			var inJson = new Object();
			inJson.orgCd = 'common';
			inJson.svcCd = 'getCertInfo';
			inJson.appCd = 'ant';// 발급받은 application 코드
			inJson.signCert = this.executeData.file1; // der형식의 인증서
			inJson.signPri = this.executeData.file2; // 인증서의 키값
			inJson.signPw = this.executeData.cert_pw;// 인증서 비밀번호

			loadNxClient('execute', JSON.stringify(inJson));

			let result = (s_op, data) => {
				var errYn = data.errYn;
				if ('execute' == s_op) {
					if (errYn == 'N') {
						this.sendData.signCert = data.DER2PEM;
						this.sendData.signPri = data.KEY2PEM;

						let FinanceGbnComp = document.getElementById("selectBoxFinance");
						let FinanceGbn = FinanceGbnComp.options[FinanceGbnComp.selectedIndex].value;
						
						if(FinanceGbn == null || FinanceGbn == ""){
							if(this.financeType == 'BANK'){
								alert("은행사정보가 옳바르지 않습니다. 다시 확인해주십시오.");
							}else{
								alert("카드사정보가 옳바르지 않습니다. 다시 확인해주십시오.");
							}
							return false;
						}

						this.sendData.scrapSiteIdx = FinanceGbn;

						//카드정보 추가
						let financeInfo = new Object();
						if(this.financeType == 'CARD'){
							financeInfo.financeNo = this.cardNo;
						}
						if(this.financeType == 'BANK'){
							financeInfo.financeNo = this.accountNo;
						}
						financeInfo.financeAlias = this.financeAlias;
						
						this.sendData.financeInfo = financeInfo;

						sendCertData(JSON.stringify(this.sendData));
					}
				}
			}

			let sendCertData = (sendData) => {
				this.$cAxios.post(this.prefix + "/cert/finance/regist", sendData, {
					headers:{
						'Content-Type' : "application/json",
					}
				}).then(()=>{
					alert("등록되었습니다.");
					this.closePopup();
				}).catch((err)=>{
					if(err.response){
						alert(err.response.data.message);
						this.$refs.form.setErrors({
							certNm:['인증서정보를 확인해 주십시오.'],
							cardNo:['카드번호를 확인해 주십시오.'],
							accountNo:['계좌번호를 확인해주십시오.'],
						})
					} 
				}).finally(()=>{
					
				});

			}
		},
		idpwAuthRegist(){
			let FinanceGbnComp = document.getElementById("selectBoxFinance");
			let FinanceGbn = FinanceGbnComp.options[FinanceGbnComp.selectedIndex].value;
			let siteId = this.siteId;
			let sitePwd = this.sitePwd;
			
			if(FinanceGbn == null || FinanceGbn == ""){
				if(this.financeType == 'BANK'){
					alert("은행사정보가 옳바르지 않습니다. 다시 확인해주십시오.");
				}else{
					alert("카드사정보가 옳바르지 않습니다. 다시 확인해주십시오.");
				}
				
				return false;
			}
			if(siteId == "" || sitePwd == ""){
				alert("인증정보를 확인해 주십시오.");
				return false;
			}

			this.sendData.scrapSiteIdx = FinanceGbn;
			this.sendData.authId = siteId;
			this.sendData.password = sitePwd;
			
			//카드정보 추가
			let financeInfo = new Object();
			if(this.financeType == 'CARD'){
				financeInfo.financeNo = this.cardNo;
        financeInfo.cdNo = this.cdNo;
			}
			if(this.financeType == 'BANK'){
				financeInfo.financeNo = this.accountNo;
			}
			financeInfo.financeAlias = this.financeAlias;
			this.sendData.financeInfo = financeInfo;

			this.$cAxios.post(this.prefix + "/idpw/finance/regist",JSON.stringify(this.sendData),{
				headers:{
					'Content-Type' : 'application/json'
				}
			}).then(()=>{
				alert("등록되었습니다.");
				this.closePopup();
			}).catch((err)=>{
				if(err.response){
					alert(err.response.data.message);
					this.$refs.form.setErrors({
						siteId:['계정정보를 확인해주십시오.'],
						sitePwd:['계정정보를 확인해주십시오.'],
						cardNo:['카드번호를 확인해 주십시오.'],
						accountNo:['계좌번호를 확인해주십시오.'],
					})
				}
			}).finally(()=>{
				this.siteId = "";
				this.sitePwd = "";
			});
		},
		setFinanceInfo(){
			//카드등록을 위한 data 셋팅
			
			let financeArray = new Array();
			let scrapFinance = new Object();
			if(this.financeType == 'CARD'){
				scrapFinance.financeNo = this.cardNo;
			}
			if(this.financeType == 'BANK'){
				scrapFinance.financeNo = this.accountNo;
			}
			scrapFinance.financeAlias = this.financeAlias;
			financeArray.push(scrapFinance);

			let cardInfo = new Object();
			cardInfo = {};
			cardInfo.idx = this.authIdx;
			cardInfo.scrapFinances = financeArray;

			this.registFinance(cardInfo);
		},
		registFinance(cardInfo){
			this.$cAxios.post(this.prefix + "/finance/regist", JSON.stringify(cardInfo), {
				headers:{
					'Content-Type': 'application/json'
				}
			}).then(()=>{
				alert("등록되었습니다.");
				this.closePopup();
			}).catch((err)=>{
				if(err.response){
					alert(err.response.data.message);

					this.$refs.form.setErrors({
						cardNo:['카드번호를 확인해 주십시오.'],
						accountNo:['계좌번호를 확인해주십시오.'],
					});
				}
			});

		},
		selfFillIn(idx){
			this.authIdx = idx;
			if(idx == ''){
				this.selfFillInYN = true;
			}else{
				this.selfFillInYN = false;
			}
		},
		registCardByCertAuth(){
			if(this.cardNo == "" || this.financeAlias == "" || this.cardNo.length <15){
				alert("카드정보를 확인해 주십시오.");
				return false;
			}

			if(this.selfFillInYN){
				//직접입력의 경우 한큐에 인증서와 카드번호 저장되도록 수정
				this.certAuthRegist();
			}else{
				this.setFinanceInfo();
			}

		},
		registCardByIdpwAuth(){
			if(this.cardNo == "" || this.financeAlias == "" || this.cardNo.length <15){
				alert("카드정보를 확인해 주십시오.");
				return false;
			}

      let financeGbnComp =  document.getElementById("selectBoxFinance");
      var financeGbn = financeGbnComp.options[financeGbnComp.selectedIndex].value;
      if(this.financeType == 'CARD' && ["33","34"].includes(financeGbn)){
        // 롯데카드 카드번호의 정확한 검증을 위해 카드사에서 카드목록 조회 후 저장
        this.registLotteCard();
      }else{
        if(this.selfFillInYN){
          this.idpwAuthRegist();
        }else{
          this.setFinanceInfo();
        }
      }



		},
		registAccountByCertAuth(){
			if(this.accountNo == "" || this.financeAlias == "" ){
				alert("계좌정보를 확인해 주십시오.");
				return false;
			}

			if(this.selfFillInYN){
				this.certAuthRegist();
			}else{
				this.setFinanceInfo();
			}
		},
		registAccountByIdpwAuth(){
			if(this.accountNo == "" || this.financeAlias == "" ){
				alert("계좌정보를 확인해 주십시오.");
				return false;
			}

			if(this.selfFillInYN){
				this.idpwAuthRegist();
			}else{
				this.setFinanceInfo();
			}
		},
		closePopup() {
		this.initFinanceInfo();

			this.supportLogin = false;
			this.supportCert = false;
			this.$emit('toParent:visible', false)
		},
    initFinanceInfo(){
      this.sendData = {};
      this.executeData = {};
      this.certNm = "";

      this.siteId = "";
      this.sitePwd = "";

      this.cardNo = "";
      this.accountNo = "";
      this.financeAlias = "";
      this.cdNo = "";
      this.errorMsg = "";
    },
    registLotteCard(){
      let param = {
        idx: this.authIdx,
        authId: this.siteId,
        password: this.sitePwd,
        financeInfo: {financeNo: this.cardNo}
      };
      let _this = this;
      this.$cAxios.post(this.prefix + "/list/lotte", param,{
        headers:{
          'Content-Type':'application/json'
        }
      })
      .then((res) => {
        if(res.data.length == 0){
          // 해당 카드를 찾을 수 없음
          alert("카드정보를 찿을수 없습니다 카드번호를 확인해주세요");
        }else if(res.data.length == 1){
          _this.cdNo = res.data[0].cdNo;
          if(_this.selfFillInYN){
            _this.idpwAuthRegist();
          }else{
            _this.setFinanceInfo();
          }
        }else{
          _this.openPopupCardSelect()
        }
      })

    },
    openPopupCardSelect(){
      this.isVisibleCardSelect = true;
    },
    placeCardNumber(item){
      this.cdNo = item.cdNo;
      this.isVisibleCardSelect = false;
      if(this.selfFillInYN){
        this.idpwAuthRegist();
      }else{
        this.setFinanceInfo();
      }
    }
	},
}
</script>