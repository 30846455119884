import DatePicker from 'vue2-datepicker';
import ko from 'date-format-parse/lib/locale/ko';
// import 'vue2-datepicker/locale/ko';
import Vue from 'vue'

const lang = {
  formatLocale: ko,
  yearFormat: 'YYYY'+'년',
  monthFormat: 'MMM',
  monthBeforeYear: false,
};

DatePicker.locale('ko', lang);

Vue.component('date-picker', DatePicker);