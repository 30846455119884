import AgentInfoService from '../services/agentInfo.service'
import UserInfoService from '../services/userInfo.service'

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const initialState = userInfo ? { status: { hasUserInfo: true }, userInfo: userInfo } : { status: { hasUserInfo: false }, userInfo: null }

export  const user = {
    namespaced: true,

    state : initialState,
    actions: {
        getAgentInfo({ commit }) {
            return AgentInfoService.getAgentInfo()
                .then(
                    agentInfo => {
                        commit('getUserInfoSuccess', agentInfo);
                        return Promise.resolve(agentInfo);
                    },
                    error => {
                        commit('getUserInfoFailure');
                        return Promise.reject(error);
                    }
                )
        },
        getUserInfo({ commit }) {
            return UserInfoService.getUserInfo()
                .then(
                    userInfo => {
                        commit('getUserInfoSuccess', userInfo);
                        return Promise.resolve(userInfo);
                    },
                    error => {
                        commit('getUserInfoFailure');
                        return Promise.reject(error);
                    }
                )
        }

    },
    mutations: {

        getUserInfoSuccess(state, userInfo) {
            state.status.hasUserInfo = true;
            state.userInfo = userInfo;
        },
        getUserInfoFailure(state) {
            state.status.hasUserInfo = false;
            state.userInfo = null;
        }
    }
}