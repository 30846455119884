<template>
	<div class="w3-modal w3-animate-opacity" v-if="$attrs.visible" style="display:block">
		<div class="w3-modal-content w3-card-4 w3-animate-zoom pt20 pb30 pr20 pl25" style="max-width:700px">
			<div class="w3-center">
				<span @click="closePopup" class="w3-button w3-xlarge w3-transparent w3-display-topright fs30 bold lh25" title="Close Modal">×</span>
			</div>
			<validation-observer ref="form" v-slot="{handleSubmit}">
			<form class="form-group" action="">
				<h4 class="login-pop-h4 tx_cnt">{{ $attrs.popupTitle }}</h4>
				<div class="w3-section mr10 ml15">
          <span class="text-red">목록에 표시되지 않은 금융기관은 인증정보 등록을 지원하지않는 금융기관 입니다.</span>
					<table class="table01">
						<caption>인증정보 추가 표</caption>
						<colgroup>
							<col style="width:150px">
							<col>
						</colgroup>
						<tr>
							<th class="tx_cnt"><label for="">대상</label></th>
							<td class="tx_lf pl15">
								<label for="" class="w80"><input type="radio" name="bizKind" id="person" value="PERSON" v-model="pickedBizKind"> 개인</label>
								<label for="" class="w80"><input type="radio" name="bizKind" id="corp" value="CORP" v-model="pickedBizKind"> 법인</label>
							</td>
						</tr>
						<tr>
							<th class="tx_cnt">
								<label v-if="financeType == 'BANK'" for="">은행사</label>
								<label v-else for="">카드사</label>
							</th>
							<td class="tx_lf pl15">
								<base-select-box
									:class="selectBoxFinance"
									:id="'selectBoxFinance'"
									:items="financeCompanyList" 
									@toParent:selectValue="val => selectedFinCompany(val)"/>
							</td>
						</tr>						
						<tr>
							<th class="tx_cnt"><label for="">인증수단</label></th>
							<td class="tx_lf pl15">
								<label v-if="supportCert" for="" class="w80"><input type="radio" name="authKind" id="cert" value="CERT" v-model="pickedAuthKind"> 인증서</label>
								<label v-if="supportLogin" for="" class="w80"><input type="radio" name="authKind" id="idpw" value="ID" v-model="pickedAuthKind"> ID/PW</label>
								<label v-if="noSupportAuth" for="" >{{noSupportAuthMsg}}</label>
							</td>
						</tr>
						<tr v-if="pickedAuthKind == 'CERT'">
							<th class="tx_cnt"><label for="">인증서명</label></th>
							<td class="tx_lf pl15">
								<validation-provider vid="certNm" rules="required" v-slot="{errors}">
									<span class="displaylinebl"><input class="form-control form-control-table w250" type="text" style="border:none; border-color:transparent;" v-model="certNm" disabled/></span>
									<button type="button" @click="loadDoc('setup', null)" class="btn btn-block btn-default pt5 pb4 btn-sm ml5">인증서 등록</button>
									<p v-if="errors[0]" class="help-block txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</p>
								</validation-provider>
							</td>
						</tr>
						<tr v-if="pickedAuthKind == 'ID'">
							<th class="tx_cnt"><label for="">웹 아이디</label></th>
							<td class="tx_lf pl15">
								<validation-provider vid="siteId" rules="required" v-slot="{errors}">
									<input class="form-control form-control-table w250" v-model="siteId" type="text" placeholder="아이디를 입력해주세요">
									<p v-if="errors[0]" class="help-block txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</p>
								</validation-provider> 
							</td>
						</tr>
						<tr v-if="pickedAuthKind == 'ID'">
							<th class="tx_cnt"><label for="">웹 패스워드</label></th>
							<td class="tx_lf pl15">
								<validation-provider vid="sitePwd" rules="required" v-slot="{errors}">
									<input class="form-control form-control-table w250" v-model="sitePwd" type="password" placeholder="비밀번호를 입력해주세요">
									<p v-if="errors[0]" class="help-block txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</p>
								</validation-provider> 
							</td>
						</tr>
					</table>
					<!-- Button -->
					<div class="btnwrap clear pt10">
						<button v-if="pickedAuthKind == 'CERT'" @click="handleSubmit(certAuthRegist)" type="button" class="btn btn-block btn-primary w115">등록</button>
						<button v-if="pickedAuthKind == 'ID'" @click="handleSubmit(idpwAuthRegist)" type="button" class="btn btn-block btn-primary w115">등록</button>
						<button @click="closePopup" type="button" class="btn btn-block btn-default mr10 ml15 w115">취소</button>
					</div>
				</div>
			</form>
			</validation-observer>
		</div>
	</div>
</template>
<script>
import { mapState } from 'vuex'

export default {
	name: 'AuthRegPopup',
	props:[
		'financeType'
	],
	data: () => ({
		prefix : "",

		selectBoxFinance: 'form-control-inline w120',
		pickedBizKind: "PERSON",
		pickedAuthKind: "",

		financeCompanyList:[],
		supportLogin : false,
		supportCert : false,
		noSupportAuth : false,
		noSupportAuthMsg : "선택가능한 인증수단이 없습니다.",

		//cert_regist_attr
		sendData:{},
		executeData:{},
		certNm:"",

		//idpw_regist_attr
		siteId: "",
		sitePwd: "",
	}),
	watch:{
		pickedBizKind(){
			this.getFinanceCompanyList();
		},
		pickedAuthKind(){
			this.sendData = {};
			this.executeData = {};
			this.certNm = "";

			this.siteId = "";
			this.sitePwd = "";
			this.$refs.form.reset();
		}
	},
  computed: {
    ...mapState([
      'user'
    ])
  },
	methods: {
		initInfo(){
			//bizKind
			if(this.financeType == "BANK"){
				this.prefix = "api/auth/bank"
			}else{
				this.prefix = "api/auth/card";
			}

			this.getFinanceCompanyList();
		},
		getFinanceCompanyList(){
			let scrapSiteInfo = new Object();
			scrapSiteInfo.bizKind = this.pickedBizKind;

			this.$cAxios.post(this.prefix+"/finance/company/list/inquriy",JSON.stringify(scrapSiteInfo),{
				headers:{
					'Content-Type':'application/json'
				}
			})
			.then((res)=>{
				if(res.data != null){
					this.financeCompanyList = [];
					this.financeCompanyList.push({
						key: '',
						value:"선택",
						supportLogin: false,
						supportCert: false,
					});
					for(var i = 0 ; i < res.data.length; i++){
						var resData = {
							key : res.data[i].idx,
							value : res.data[i].name,
							supportLogin : res.data[i].supportLogin,
							supportCert : res.data[i].supportCert
						};
						this.financeCompanyList.push(resData);
					}
					
					this.supportLogin = false;
					this.supportCert = false;
					this.pickedAuthKind = '';
				}

			}).catch((err)=>{
				console.log(err.response);
			}).finally(()=>{

			});
			
		},
		selectedFinCompany(val){
			if(val == '') {
				this.pickedAuthKind = ''; //인증수단
				this.noSupportAuth = false;
				this.supportLogin = false;
				this.supportCert = false;
				return false;
			}

			this.financeCompanyList.filter((comp) => {
				if(comp.key == val) {
					if(comp.supportCert == true && this.financeType == 'BANK' && this.user.userInfo.CLIENT_BANK_CERT_ENABLE == "true"){
						this.supportCert = true;
					} else if (comp.supportCert == true && this.financeType == 'CARD' && this.user.userInfo.CLIENT_CARD_CERT_ENABLE == "true"){
						this.supportCert = true;
					}
					this.supportLogin = comp.supportLogin;
					if(this.supportCert == true){
						this.pickedAuthKind = "CERT";
						this.noSupportAuth = false;
					}else if(this.supportLogin == true){
						this.pickedAuthKind = "ID";
						this.noSupportAuth = false;
					}else{
						this.pickedAuthKind = '';
						this.noSupportAuth = true;
					}
				}
			})
		},
		loadDoc (s_op, s_inJson) {
			let loadNxClient = (v_s_op, v_s_inJson) => {
				let sUrl = "https://127.0.0.1:16565/?op=";
				if(v_s_op == 'certSelect'){
					sUrl = "https://127.0.0.1:16566/?op=";
				}

				this.$cAxios.post(sUrl+v_s_op, v_s_inJson ,{
					crossDomain: true,
					headers:{
						'Content-type':'application/json; charset=UTF-8',
					},
				}).then((res) =>{
					result(v_s_op, res.data);
				}).catch((err) => {
					if(err.request){ 
						if(err.config.url.indexOf('?op=setup') != -1){
							alert("공인인증서 등록 프로그램 설치가 필요합니다.");
							fnNxDownload();
						}
					}

				}).finally(() => {
				});

			}

			//첫 로드
			loadNxClient(s_op, s_inJson);
			let result = (s_op, data) => {
				var errYn = data.errYn;
				if('setup' == s_op){
					if (errYn == 'N') {
						//certImageUrl : 배너이미지
						loadNxClient('certSelect', '{"certImageUrl": "", "nxKeypad": ""}');
					}
				} else if ('certSelect' == s_op) {
					//init
					this.sendData = {};
					this.executeData = {};
					this.certNm = "";

					//인증서 선택
					this.sendData.certNm = data.cert_nm; //인증서명
					this.sendData.certPubDate = data.pub_dt;
					this.sendData.certEndDate = data.end_dt;
					this.sendData.certOrgNm = data.org_nm;//인증기관명 (yessign 등...)
					this.sendData.certPolicyId = data.oid;
					this.sendData.certSerialNo = data.sn;
					this.sendData.password = data.cert_pw;

					this.certNm = data.cert_nm;

					this.executeData.file1 = data.file1;
					this.executeData.file2 = data.file2;
					this.executeData.cert_pw = data.cert_pw;
					// callExecute(data);
				} 

			}

			let fnNxDownload = () => {
				location.href = "/files/ExAdapter_Web_Setup_20200611.exe";
			}

		},
		certAuthRegist(){
			if(Object.keys(this.sendData).length === 0 && this.sendData.constructor === Object) {
				alert("인증서를 등록해주십시오");
				return false;
			}

			let loadNxClient = (v_s_op, v_s_inJson) => {
				let sUrl = "https://127.0.0.1:16565/?op=";
				this.$cAxios.post(sUrl+v_s_op, v_s_inJson ,{
					crossDomain: true,
					headers:{
						'Content-type':'application/json; charset=UTF-8',
					},
				}).then((res) =>{
					result(v_s_op, res.data);
				}).catch((err) => {
					if(err.request){ 
						console.log(err.request);
					}
				}).finally(() => {
				});

			}

			var inJson = new Object();
			inJson.orgCd = 'common';
			inJson.svcCd = 'getCertInfo';
			inJson.appCd = 'ant';// 발급받은 application 코드
			inJson.signCert = this.executeData.file1; // der형식의 인증서
			inJson.signPri = this.executeData.file2; // 인증서의 키값
			inJson.signPw = this.executeData.cert_pw;// 인증서 비밀번호

			loadNxClient('execute', JSON.stringify(inJson));

			let result = (s_op, data) => {
				var errYn = data.errYn;
				if ('execute' == s_op) {
					if (errYn == 'N') {
						
						this.sendData.signCert = data.DER2PEM;
						this.sendData.signPri = data.KEY2PEM;

						let financeGbnComp = document.getElementById("selectBoxFinance");
						let financeGbn = financeGbnComp.options[financeGbnComp.selectedIndex].value;
						
						if(financeGbn == null || financeGbn == ""){
							if(this.financeType == "BANK"){
								alert("은행사정보가 옳바르지 않습니다. 다시 확인해주십시오.");
							}else{
								alert("카드사정보가 옳바르지 않습니다. 다시 확인해주십시오.");
							}
							return false;
						}

						this.sendData.scrapSiteIdx = financeGbn;

						sendCertData(JSON.stringify(this.sendData));
					}
				}
			}

			let sendCertData = (sendData) => {
				let that = this;
				this.$cAxios.post(that.prefix + "/cert/regist", sendData, {
					headers:{
						'Content-Type' : "application/json",
					}
				}).then(()=>{
					alert("등록되었습니다.");
					this.closePopup();
					this.authReInquriy();
				}).catch((err)=>{
					if(err.response){
						alert(err.response.data.message);
					}
				}).finally(()=>{
				});

			}
		},
		idpwAuthRegist(){
			let financeGbnComp = document.getElementById("selectBoxFinance");
			let financeGbn = financeGbnComp.options[financeGbnComp.selectedIndex].value;
			
			if(financeGbn == null || financeGbn == ""){
				if(this.financeType == "BANK"){
					alert("은행사정보가 옳바르지 않습니다. 다시 확인해주십시오.");
				}else{
					alert("카드사정보가 옳바르지 않습니다. 다시 확인해주십시오.");
				}
				return false;
			}
			
			if(this.siteId == null || this.siteId == "" ||
				this.sitePwd == null || this.sitePwd == ""){
				alert("인증정보를 확인해주십시오.");
				return false;
			}

			this.sendData.scrapSiteIdx = financeGbn;
			this.sendData.authId = this.siteId;
			this.sendData.password = this.sitePwd;

			this.$cAxios.post(this.prefix + "/idpw/regist",JSON.stringify(this.sendData),{
				headers:{
					'Content-Type' : 'application/json'
				}
			}).then(()=>{
				alert("등록되었습니다.");
				this.closePopup();
				this.authReInquriy();
			}).catch((err)=>{
				if(err.response){
					alert(err.response.data.message);
				}
			});
		},
		closePopup() {
			this.sendData = {};
			this.executeData = {};
			this.certNm = "";

			this.siteId = "";
			this.sitePwd = "";
			this.$emit('toParent:visible', false)
		},
		authReInquriy(){
			this.$emit('toParent:reInqury');
		}
	},
	mounted:function(){
		this.initInfo();
	}
}
</script>