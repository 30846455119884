<template>
	<div class="card shadow">
		<slot name="slot_card_header" />
		<!-- 인증정보-홈택스(개인)-목록 -->
		<div class="card-body">
			<table class="table01">
				<caption>{{ caption }}</caption>
				<colgroup>
					<col v-for="(th, index) in heads" :key="index" style="th.style">
				</colgroup>
				<thead>
					<tr>
						<th v-for="(th, index) in heads" :key="index" class="tx_cnt">{{ th.col }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in items" :key="index">
						<th class="tx_cnt bg-gray-100">{{ item.subject }}</th>
						<td v-for="( item, value, index) in item.tds" :key="index" class="tx_cnt">
							<!-- 인증 정보 존재할 경우 -->
							<span v-if="item != null">
								<!-- status 구분 : valid invalud unvalid -->
								<span v-if="value == 'status'">등록</span>
								<!-- <span v-else-if="value == 'status' && item != 'valid'">미등록</span> -->
								<span v-else>{{ item }}</span>
							</span>
							<!-- 인증 정보 존재안할 경우 -->
							<span v-if="item == null">
								<span v-if="value == 'status'">미등록</span>
							</span>
						</td>
							<!-- td Buttons -->
						<td class="tx_cnt pl12">
							<!-- 인증 정보 존재할 경우 -->
							<span v-if="item.tds.name">
								<p class="btn-list02"><button type="button" @click="reRegister(item.idx)" class="btn btn-block btn-default btn-sm mr10">재등록</button></p>
								<p class="btn-list03"><button type="button" @click="deleteRow(index)" class="btn btn-block btn-default btn-sm">삭제</button></p>
							</span>
							<!-- 인증 정보 존재안할 경우 -->
							<span v-else>
								<p class="btn-list03"><button type="button" class="btn btn-block btn-default" @click="reRegister(item.idx)">등록</button></p>
							</span>
							</td>
						<!-- //td Buttons -->
						</tr>
				</tbody>
			</table>
				<!-- 검증결과 table -->
			<table class="table02 mt20">
				<caption>검증결과 표</caption>
				<colgroup>                                   
					<col style="width:180px">
					<col>
				</colgroup>
				<tr>
					<!-- 검증결과 : 수정 필요  -->
					<th class="tx_cnt bg-redlight">검증결과</th>
					<td class="tx_lf pl20" >
						<span class="txt-primary" v-if="invalidCnt == 0 && unvalidCnt == 0">정상</span>
						<span class="text-gray4" v-if="unvalidCnt > 0">미검증</span>
						<span class="txt-danger" v-if="invalidCnt == 3">
							<span class="txt-danger">오류내용 : {{ totalErrorMsg.status_desc }}</span>
						</span>
					</td>
				</tr>
			</table>
			<!-- //검증결과 table -->
			
			<slot name="slot_pagination" />
		</div>
	</div>
</template>
<script>

export default {
	name: 'TableContentsAgent',

	props: {
		tableTitle: {
			type: String,
			required: false,
			default: ''
		},
		caption: {
			type: String,
			required: false,
			default: ''
		},
		heads: {
			type: Array,
			required: true,
			default: undefined,
		},
		items: {
			type: Array,
			required: false,
			default: undefined,
		},
		totalErrorMsg: {
			type: Object,
			required: false,
			default: undefined,
		},
		optionalBtns: {
			type: Boolean,
			required: false,
			default: false
		},
		// 검증 결과 테이블용 cnt... 수정 필요할 듯
		invalidCnt: {
			type: Number,
			required: true,
			default: 0,
		},
		unvalidCnt: {
			type: Number,
			required: true,
			default: 0,
		},

	},
	data: () => ({		
		// Variable		
		
		// Components attrs

	}),
	methods: {
		openLoginIdPwdPopup() {
			this.$emit('toParent:visible1', true)
		},
		deleteRow(index){
			this.$emit('toParent:deleteCurrRow', index);
		},
		reRegister(idx){
			if(idx == "1"){
				//세무대리인증서 프로그램
				this.$emit('toParent:updateCert');
			}
			else if(idx == "2"){
				//세무대리관리번호 팝업
				this.$emit('toParent:updateManageNo');
			}
			else {
				//회원정보 팝업
				this.$emit('toParent:updateMemberId');
			}
			
		},
	},

}
</script>