<template>
	<div class="w3-modal w3-animate-opacity" v-if="$attrs.visible" style="display:block">
		<div class="w3-modal-content w3-card-4 w3-animate-zoom pt20 pb30 pr20 pl25" style="max-width:700px">
			<div class="w3-center">
				<span @click="closePopup" class="w3-button w3-xlarge w3-transparent w3-display-topright fs30 bold lh25" title="Close Modal">×</span>
			</div>
			<form class="form-group" action="">
				<h4 class="login-pop-h4 tx_cnt">{{ $attrs.popupTitle }}</h4>
				<div class="w3-section mr10 ml15">
					<div class="radio credit-table-block tx_cnt pt20 pb20 mb10">
						<!-- 카드사,인증방식,인증서명 --> 
						<table class="table0">
							<caption>금융사정보 등록사항</caption>
							<colgroup>
								<col style="width:100px">
								<col style="width:100px">
								<col style="width:100px">
								<col style="width:100px">
								<col style="width:100px">
								<col>
							</colgroup>
							<tr>
								<th class="tx_rt"><i class="fas fa-caret-right fs14"></i> {{$attrs.scrapSiteType == "BANK" ? '은행사' : '카드사'}}</th>
								<td class="tx_lf">{{$attrs.scrapSiteName}}</td>
								<th class="tx_rt"><i class="fas fa-caret-right fs14"></i> 인증방식</th>
								<td class="tx_lf">{{$attrs.authType == 'CERT'? '인증서':'ID/PW'}}</td>
								<th class="tx_rt"><i class="fas fa-caret-right fs14"></i> 사업자유형</th>
								<td class="tx_lf">{{$attrs.bizKind == "PERSON" ? '개인':'법인'}}</td>
							</tr>
						</table>
					</div>
					<!-- 인증서 상세 정보 리스트 -->
					<table class="table01">
						<caption>{{ $attrs.caption }}</caption>
						<colgroup>
							<col v-for="(item, index) in $attrs.heads" :key="index" :style="item.style">
							<!-- <col style="width:150px">
							<col> -->
						</colgroup>
						<tr v-for="(item, index) in $attrs.bodys" :key="index">
							<th class="tx_cnt" >{{ item.th }}</th>
							<td class="tx_lf pl15" v-if="$attrs.gubun == 'idpwd' && item.th == '웹 패스워드'">
								<input class="form-control form-control-table w100" :id="'password'+index" type="password" v-model="password" :disabled="vChangeYn == false">
							</td>
							<td class="tx_lf pl15" v-if="item.th != '상태' && item.th != '인증상태' && item.th != '상태상세' && item.th != '웹 패스워드'">{{ item.td }}</td>
							<td class="tx_lf pl15 txt-danger" v-if="$attrs.gubun == 'idpwd' && item.th == '인증상태' && item.td == '오류'">{{ item.td }}</td>
							<td class="tx_lf pl15 txt-primary" v-if="$attrs.gubun == 'idpwd' && item.th == '인증상태' && item.td == '정상'">{{ item.td }}</td>
							<td class="tx_lf pl15 txt-danger" v-if="$attrs.gubun == 'cert' && item.th == '상태' && item.td == '오류'">{{ item.td }}</td>
							<td class="tx_lf pl15 txt-primary" v-if="$attrs.gubun == 'cert' && item.th == '상태' && item.td == '정상'">{{ item.td }}</td>
							<!-- <td class="tx_lf pl15 txt-danger" v-if="$attrs.gubun == 'idpwd' && item.th == '상태상세'">
								<span v-if="item.td != null" class="txt-danger"><i class="fa fa-times-circle-o has-error"></i>{{ item.td }}</span>
							</td> -->
						</tr>
					</table>
					<!-- //인증서 상세 정보 리스트 -->
					<!-- Button -->
					<slot name="slot_btns"/>
				</div>
			</form>
		</div>
	</div>
</template>
<script>

export default {
	name: 'LoginDetailsInfoPopup',
	props:{
		idx : {type:String},
		changeYn : {type:Boolean},
		updateOptions : {type:Object}
	},
	data:()=>({
		password:"********",
	}),
	computed:{
		vChangeYn(){
			return this.changeYn;
		},
		vExecuteUpdate(){
			return this.updateOptions;
		},

	},
	watch:{
		vChangeYn(val){
			if(!val){
				this.password = '********';
			}else{
				this.password = '';
			}
		},
		vExecuteUpdate(obj){
			if(obj != null){
				if(obj.updateYN == true && obj.type == 'ID'){
					this.executePwdUpdate();
				}else if(obj.updateYN == true && obj.type == 'CERT'){
					this.executeCertUpdate();
				}
			}
		}
	},
	methods: {
		executePwdUpdate(){
			let password = document.getElementById("password1").value;
			if(password.length < 1){
				alert("패스워드를 입력해주십시오.");
				
			}else{
				this.$emit('toParnet:executePwdUpdate',this.idx, password);
			}
			
		},
		executeCertUpdate(){
			this.$emit('toParent:executeCertUpdate',this.idx);
		},
		closePopup() {
			this.$emit('toParent:visible', false)
		},
	},
}
</script>