import AuthService from '../services/auth.service'

const userRole = JSON.parse(localStorage.getItem('userRole'));

// user 정보가 null일 때 null이 아닐 때 초기화 
const initialState = userRole ? { status: { passedAuth: true } , userRole}  : { status: { passedAuth: false }, userRole: null }

// state를 mutation시키느냐 or action으로 mutation을 커밋하느냐
export const auth = {
	namespaced: true,

	state: initialState,
	actions: {
		// API서버 통신과 같은 역할을 수행하는 메소드 그룹
		// 주로 state에 반영하기 전 데이터를 조회하고 가공하는 역할을 수행
		authenticate({ commit }) {
			return AuthService.authenticate(userRole)
				.then(
				userRole => {
					commit('authenticationSuccess', userRole);
					return Promise.resolve(userRole);
					// return new Promise(function (resolve, reject) {
					// 	resolve(userRole)
					// })
				},
				error => {
					commit('authenticationFailure');
					return Promise.reject(error);
				}
			);
		}
		// authenticate() end

	},
	mutations: {
		// state를 변경시키기 위한 메서드 집합
		// state조작은 오로지 mutation 메소드를 통해 수정하길 권장
		authenticationSuccess(state, userRole) {
			state.status.passedAuth = true;
			state.userRole = userRole;
		},
		authenticationFailure(state) {
			state.status.passedAuth = false;
			state.userRole = null;
		}
	},
}