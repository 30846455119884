import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth.module'
import { user } from './user.module'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		// Auth Store Module
		auth, user,
	},

	// Common Store
	state: {
		// 1. common
		corpTypeList: [{key: '', value: "전체"}, {key: 'PERSON', value: "개인"}, {key: 'CORP', value: "법인"}],
		//	세목 item
		taxItemList: [
			//	2021-04-27 현재 부가세/원천세/종소세 외 아직 미개발인 세목 노출 안되도록 주석처리
			{key: '', value: '세목 선택'},
			/*{ key: 'C66', value: '교육세(보험)' },
			{ key: 'C65', value: '교육세(금융)' },
			{ key: 'C59', value: '교통,에너지,환경세' },
			{ key: 'C57', value: '종합부동산세' },
			{ key: 'C47', value: '개별소비세' },
			{ key: 'C46', value: '인지세' },
			{ key: 'C45', value: '증권거래세' },
			{ key: 'C43', value: '주세' },*/
			{key: 'C41', value: '부가가치세'},
			/*{ key: 'C33', value: '증여세' },
			{ key: 'C31', value: '법인세' },
			{ key: 'C22', value: '양도소득세' },*/
			{key: 'C14', value: '원천세'},
			{key: 'C10', value: '종합소득세'},
		],

		// 2. page > scrap
		/*
		CREDIT : 여신
		NHIS : 국민건강보험공단
		NPS : 국민연금관리공단
		MEDI : 요양기관정보마당
		HIRA : 요양기관업무포털
		CARD_TERMINAL : 카드단말기
		*/
		isScrapGroupNeedCheckBox: ['CARD', 'BANK', 'CREDIT', 'NHIS', 'NPS', 'HIRA', 'CARD_TERMINAL'],
		// 2-0. 스크랩핑 조회기준 구분값
		scrapPeriodStandard: '',
		// 2-1. 홈택스용 조회기준별 해당 scrapId
		hometax_from_to_ymd: [
			"HOMETAX_INVOICE_TAX_SALE", "HOMETAX_INVOICE_TAX_BUY", "HOMETAX_INVOICE_SALE", "HOMETAX_INVOICE_BUY",
			"HOMETAX_AGT_REFUND", "HOMETAX_CASH_BUY", "HOMETAX_VAT_HELP", "HOMETAX_DECLARE_RESULT",	//agent
			"HOMETAX_BIZ_CARD_CFM",	//user
		],
		hometax_year_quarter: ["HOMETAX_CARD_SALE_DATA"],
		hometax_year: ["HOMETAX_AGT_CSH_SAL_TOTAL", "HOMETAX_FOUR_INSR", "HOMETAX_INCOME_HELP","HOMETAX_PAY_STATEMENT"],
		hometax_unnecessary: ["HOMETAX_VAT_SCH_TAX", "HOMETAX_AGT_NOTICE", "HOMETAX_AGT_ARREAR", "HOMETAX_BIZ_ACCT_DCL",],
		hometax_year_vat: ["HOMETAX_INVOICE_SUM"],

		// 2-2. 여신용 조회기준별 해당 scrapId
		credit_from_to_ym: ["CARD_SALES"],
		// 2-3. 건강보험용 조회기준별 해당 scrapId
		nhis_from_to_ym: ["NHIS_HELTH_PAY", "NHIS_FOUR_INSR", "NHIS_PERSONAL_FOUR_INSR"],
		// 2-4. 국민연금용 조회기준별 해당 scrapId
		nps_from_to_ym: ["NPS_ANN"],
		// 2-5.	요양기관정보마당 조회기준별 해당 scrapId
		medi_from_to_ymd: ["MEDI_DIABETES"],
		medi_year: ["MEDI_YEAR_PAYMENT"],
		// 2-6. 카드단말기 조회기준별 해당 scraipId
		card_terminal_from_to_ym: ["CARD_TERMINAL_SALES"],
		// etc. 그 외 조회기준 구분값
		etc_from_to: [],

		isNeedResNoScrap: ["HOMETAX_FOUR_INSR"],

	},
	mutations: {
		// payload: scrapId
		// scrapId로 조회기준(yyyyMM/yyyyMMdd/연도분기/연도/없음) !컴포넌트! 구분하기 위한 메서드
		SET_SCRAP_HOMETAX_PERIOD_STANDARD(state, payload) {
			if (state.hometax_from_to_ymd.includes(payload)) {
				state.scrapPeriodStandard = "hometax_from_to_ymd";

			} else if (state.hometax_year_quarter.includes(payload)) {
				state.scrapPeriodStandard = "hometax_year_quarter";

			} else if (state.hometax_year.includes(payload)) {
				state.scrapPeriodStandard = "hometax_year";

			} else if (state.hometax_unnecessary.includes(payload)) {
				state.scrapPeriodStandard = "hometax_unnecessary";
			} else if (state.hometax_year_vat.includes(payload)) {
				state.scrapPeriodStandard = "hometax_year_vat";
			}
		},
		SET_SCRAP_CREDIT_PERIOD_STANDARD(state) {
			state.scrapPeriodStandard = "credit_from_to_ym";
		},
		SET_SCRAP_NHIS_PERIOD_STANDARD(state) {
			state.scrapPeriodStandard = "nhis_from_to_ym";
		},
		SET_SCRAP_NPS_PERIOD_STANDARD(state) {
			state.scrapPeriodStandard = "nps_from_to_ym";
		},
		SET_SCRAP_MEDI_PERIOD_STANDARD(state, payload) {
			if (state.medi_from_to_ymd.includes(payload)) {
				state.scrapPeriodStandard = "medi_from_to_ymd";
			} else if (state.medi_year.includes(payload)) {
				state.scrapPeriodStandard = "medi_year";
			}
		},
		SET_SCRAP_CARD_TERMINAL_PERIOD_STANDARD(state) {
			state.scrapPeriodStandard = "card_terminal_from_to_ym";
		},
		SET_SCRAP_DEFAULT_PERIOD_STANDARD(state) {
			state.scrapPeriodStandard = "default_from_to_ymd";
		}
	},
	actions: {


	},
	getters: {

	}
})