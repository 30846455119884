<template>
  <div class="w3-modal w3-animate-opacity" v-if="visible" style="display:block">
    <div class="w3-modal-content w3-card-4 w3-animate-zoom pt20 pb30 pr20 pl25">
      <div class="w3-center">
        <span @click="closePopup" class="w3-button w3-xlarge w3-transparent w3-display-topright fs30 bold lh25" title="Close Modal">×</span>
      </div>
        <h4 class="login-pop-h4 tx_cnt">{{ $attrs.popupTitle }}</h4>
        <div class="w3-section mr10 ml15">
          <!-- 검색영역 -->
          <div class="credit-table-block pl20 pt20 pb20 mb20" v-if="$attrs.needSearchAndPaging">
            <!-- 1 row -->
            <div class="tx_lt pb5 pt5">
              <label class="ml15 mr10 bold"><i class="fas fa-caret-right fs14 mr5"></i>사업자번호 검색</label>
              <input class="w150" type="text" placeholder="사업자번호 입력" v-model="enteredCorpRegNo" />
              <label class="ml15 mr10 bold"><i class="fas fa-caret-right fs14 mr5"></i>생년월일 검색</label>
              <input class="w150" type="text" placeholder="생년월일 입력" v-model="enteredBirthday" />
            </div>
            <!-- 2 row -->
            <div class="tx_lt pb5 pt5">
              <!-- 상호 -->
              <label class="ml5 mr10 bold"><i class="fas fa-caret-right fs14 ml10 mr5"></i>상호</label>
              <input class="w130" type="text" placeholder="상호 입력" v-model="enteredCorpName" />
              <!-- 대표자 -->
              <label class="ml5 mr10 bold"><i class="fas fa-caret-right fs14 ml10 mr5"></i>대표자</label>
              <input class="w130" type="text" placeholder="대표자 입력" v-model="enteredReprName" />

              <div class="btnwrap-right">
                <button type="button" class="btn btn-darkgrey ml15 mr20 w120" @click.prevent="getDetailsHistoryData(true)">조회</button>
              </div>
            </div>
          </div>
          <!-- //검색영역 -->
          <div class="tx_rt pb10" v-if="$attrs.scrapPeriodStandard == 'hometax_year_quarter'">
            <label class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14"></i>&nbsp;&nbsp;조회기준</label>
            <span>{{ $attrs.year }}년도</span>&nbsp;&nbsp;<span>{{ $attrs.yearQuarter }}</span>
          </div>
          <div class="tx_rt pb10" v-else-if="$attrs.scrapPeriodStandard == 'hometax_year'">
            <label class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14"></i>&nbsp;&nbsp;조회기준</label>
            <span>{{ $attrs.year }}년도</span>
          </div>
          <div class="tx_rt pb10" v-else-if="$attrs.scrapPeriodStandard == 'hometax_unnecessary'">
            <label class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14"></i>&nbsp;&nbsp;조회기준</label>
            <span> - </span>
          </div>
          <div class="tx_rt pb10" v-else-if="$attrs.scrapPeriodStandard == 'hometax_from_to_ymd' || $attrs.scrapPeriodStandard == 'nhis_from_to_ym' || $attrs.scrapPeriodStandard == 'nps_from_to_ym'">
            <label class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14"></i>&nbsp;&nbsp;조회기준</label>
            <span>{{ startDate }}</span> ~ <span>{{ endDate }}</span>
          </div>
			<div class="tx_rt pb10" v-if="$attrs.scrapPeriodStandard == 'hometax_year_vat'">
				<label class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14"></i>&nbsp;&nbsp;조회기준</label>
				<span>{{ $attrs.year }}년도</span>&nbsp;&nbsp;<span>{{ $attrs.vatQuarter }}</span>
			</div>
          <table class="table01">
            <caption>세무대리인 사업장별 수동실행 결과 상세</caption>
            <colgroup>
              <col v-for="(item, index) in table_heads" :key="index" :style="item.style">
            </colgroup>
            <thead>
              <tr>
                <th v-for="(item, index) in table_heads" :key="index" class="tx_cnt" v-html="item.col">{{ item.col }}</th>
              </tr>
            </thead>
            <tbody v-if="table_items.length > 0">
              <tr v-for="(item, index) in table_items" :key="index">
                <td class="tx_cnt">{{ item.bizKind.displayName }}</td>
                <td class="tx_cnt">{{ item.corpRegNo }}</td>
                <td class="tx_cnt">{{ item.resNo }}</td>
                <td class="tx_cnt">{{ item.corpName }}</td>
                <td class="tx_cnt">{{ item.reprName }}</td>
                <td class="tx_cnt">{{ item.count }}</td>
                <td class="tx_cnt txt-primary" v-if="item.status.code == 'SUCCESS'">{{ item.status.displayName }}</td>
                <td class="tx_cnt txt-danger" v-if="item.status.code == 'FAIL' || item.status.code == 'SEND_FAIL'">실패</td>
                <td class="tx_lt">{{ item.resultMessage }}</td>
              </tr>
            </tbody>
            <tbody v-if="table_items.length == 0">
            <tr>
              <td class="tx_cnt" colspan="8"> 일치하는 데이터가 없습니다. </td>
            </tr>
            </tbody>
          </table>
          <base-pagination
            :prefix="'/scrap/manual'"
            :pTotalListItemCount="totalListCnt"
            :pageInit="pageInit"
            @toParent:changePage="(val1, val2) => currPage = val2"
            v-if="$attrs.needSearchAndPaging" />
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "ScrapManualDetailsPopup",

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    scrapId: {
      type: String,
      // required: true,
      default: ''
    },
    groupKey: {
      type: String,
      // required: true,
      default: ''
    },
    fromDate: {
      type: String,
      // required: true
    },
    toDate: {
      type: String,
      // required: true
    },
  },
  data: () => ({
    //Variables
    //검색영역

    startDate: '시작일',
    endDate: '종료일',
    enteredCorpRegNo: '',
    enteredBirthday: '',
    enteredCorpName: '',
    enteredReprName: '',
    table_heads: [
      {col: '구분', style: 'width:50px'},
      {col: '사업자번호', style: 'width:120px'},
      {col: '주민번호', style: 'width:120px'},
      {col: '상호', style: 'width:100px'},
      {col: '대표자', style: 'width:70px'},
      {col: '건수', style: 'width:50px'},
      {col: '결과', style: 'width:50px'},
      {col: '비고', style: ''},
    ],
    table_items: [],

    //페이지 관련
    currPage: 1,
    pageLimit: 10,   //backend 서버 페이징처리용
    pageInit: false, //현재 페이지 초기화 여부 체크용
    totalListCnt: 0, //조회내역 총 갯수. 페이지수 계산용으로 필요

    //Components attrs

  }),
  methods: {
    //========== 조회 메서드(페이징) .  ==========
    getDetailsHistoryData(pageReloadYn) {

      if (pageReloadYn) {
        this.pageInit = true;	//현재 페이지 초기화
        this.currPage = 1;    //이 스크립트용 현재페이지값 초기화 (서버 보내줘야함)
        this.totalListCnt = 0;//페이지수 초기화
      }

      this.param = {
        historyGroupKey: this.groupKey,
        pageIndex: this.currPage - 1,
        pageLimit: this.pageLimit
      }
      // 사업자번호
      this.param.corpRegNo = this.enteredCorpRegNo;
      // 주민번호(생년월일)
      this.param.birthday = this.enteredBirthday;
      //  상호
      this.param.corpName = this.enteredCorpName;
      //  대표자
      this.param.reprName = this.enteredReprName;

      this.table_items = [];
      this.startDate = '';
      this.endDate = '';

      this.$cAxios.post('/api/scrap/manual/getDetailsHistoryForAgent', this.param, this.header)
          .then( response => {

            if (response.data.content.length > 0) {
              this.totalListCnt = response.data.totalElements;

              for (var i=0; i<response.data.content.length; i++) {

                let bizKind = response.data.content[i].bizKind.code;
                //  사업자번호 format
                let corpRegNo = response.data.content[i].corpRegNo;
                if (corpRegNo != null) {
                  response.data.content[i].corpRegNo = this.formatCorpRegNo(corpRegNo);
                }

                //  table items 세팅
                this.table_items.push(response.data.content[i]);
              }
              if (pageReloadYn) {
                //  조회기간 일자 보여주기
                this.startDate = this.getFormatDate(this.fromDate, '-');
                this.endDate = this.getFormatDate(this.toDate, '-');
              }
            }
          })
          .catch(error => {
            console.log(error.response.data.message);
          })
          .finally(() => {
            this.pageInit = false;
          })
    },
    //========== 기타 메서드.  ==========
    //  사업자번호 format
    formatCorpRegNo(str) {
      let originStr = str.toString();
      let formattedStr = '';

      if (originStr != null || originStr != '') {
        if (originStr.length == 10) {
          formattedStr = originStr.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
        }
      }
      return formattedStr;
    },
    // 일자  - 제거
    getFormatDate(date, str) {
      var arr = date.split(str);
      var formatDate = '';
      for (var i=0; i<arr.length; i++) {
        formatDate += arr[i];
      }
      return formatDate;
    },
    closePopup() {
      this.pageInit = true;	//현재 페이지 초기화
      this.totalListCnt = 0;	//페이지수 초기화
      this.enteredCorpRegNo = '';
      this.enteredBirthday = '';
      this.enteredCorpName = '';
      this.enteredReprName = '';

      this.$emit('toParent:visible', false);
    },
    isNeedResNoScrapYn() {
      return this.isNeedResNoScrap.includes(this.scrapId);
    }
  },
  computed: {
    ...mapState(['isNeedResNoScrap']),
  },
  watch: {
    visible(val) {
      //  팝업 오픈시 groupKey로 데이터 조회
      if (!(this.groupKey == null || this.groupKey == '' || this.groupKey == undefined)) {
        if (val == true) {
          this.table_items = [];
          this.enteredCorpRegNo = '';
          this.enteredBirthday = '';
          this.enteredCorpName = '';
          this.enteredReprName = '';

          this.pageInit = true;	//현재 페이지 초기화
          this.totalListCnt = 0;	//페이지수 초기화

          this.getDetailsHistoryData(true);
        }
      }
    },
    currPage() {
      this.getDetailsHistoryData(false);
    },
  }
}
</script>

<style scoped>

</style>