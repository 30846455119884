<template>
	<div>
		<!--		세목 selectBox -->
    <slot name="slot_selectBox_taxItem" />

		<label class="tx_rt ml20 mr10 bold" v-if="$attrs.isDateGubun == false"><i class="fas fa-caret-right fs14"></i> 조회기간 설정</label>

		<base-select-box
			:class="selectBoxClass1"
			:items="selectBoxItems_date_gubun"
			@toParent:selectValue="val => changeDateGubun(val)"
			v-if="$attrs.isDateGubun == true" />

		<date-picker ref="fromDate" class="w200" v-model="fromDate" :editable="false" type="date" value-type="format" placeholder="2020-00-00" width="500" @change="changeFromDate($event)" @keyup.native="keyupFromDate($event)"></date-picker>
		<span class="datepicker-span"> ~ </span>
		<date-picker ref="toDate" class="w200" v-model="toDate" :editable="false" type="date" value-type="format" placeholder="2020-00-00" width="500" @change="changeToDate($event)" @keyup.native="keyupToDate($event)"></date-picker>

		<!-- <date-picker v-model="date2" type="date" range value-type="DD/MM/YYYY" confirm width="500"></date-picker><br/>
		{{ date2 }}<br/>
		<date-picker v-model="date3" range value-type="format" :shortcuts="shortcuts" confirm></date-picker><br/>
		{{ date3 }}<br/>
		<date-picker v-model="date4" range value-type="date" placeholder="2020-00-00 ~ 2020-00-00" confirm>
		  <template v-slot:header="{ emit }">
			<button class="mx-btn mx-btn-text" @click="selectNextThreeDay(emit)">오늘</button>
			<button class="mx-btn mx-btn-text" @click="selectNextThreeDay(emit)">어제</button>
			<button class="mx-btn mx-btn-text" @click="selectNextThreeDay(emit)">그제</button>
		  </template>
		</date-picker><br/>
		{{ date4 }}<br/> -->

		<!-- 검색영역 안에서 조회버튼 넣어야하는 row면 넣기 -->
		<slot name="slot_searchBtn" />
	</div>
</template>
<style lang="scss">
@import '~vue2-datepicker/scss/index.scss';		
</style>
<script>

export default {
  name: 'BaseCalendarInSearchRow',

  data: () => ({
    selectBoxClass1: 'form-control pl5 form-control-inline w150 mr5',
    selectBoxClass2: 'form-control pl5 form-control-inline w100 mr5',
    selectBoxItems_date_gubun: [
      {key: '', value: '선택',},
      {key: 'inqDate', value: '조회일자 기준',},
      {key: 'sendDate', value: '시행일자 기준',},
    ],
	  selectBoxItems_taxItem: [
		  // {key: '', value: '세목 선택',},
		  // {key: '10', value: '종합소득세',},
		  // {key: '14', value: '원천세',},
		  // {key: '31', value: '법인세',},
		  // {key: '41', value: '부가가치세',},
	  ],

	fromDate: '',
	toDate: '',

    // lang: {
    // 	days: ['월', '화', '수', '목', '금', '토', '일'],
    // 	months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    // 	pickers: ['오늘', '어제', '7일 전', '한달 전', '3달 전', '6개월 전'],
    // 	placeholder: {
    // 		date: '스타트업',
    // 		dateRange: '카이로스',
    // 	},
    // },

    // shortcuts: [
    // 	{
    // 		text: '오늘',
    // 		onClick: () => {
    // 			const date = [new Date(), new Date()];

    // 			return date;
    // 		}
    // 	},
    // 	{
    // 		text: '어제',
    // 		onClick: () => {
    // 			const date1 = new Date();
    // 			const date2 = new Date();

    // 			date1.setTime(date1.getTime() - 3600 * 1000 * 24)
    // 			date2.setTime(date2.getTime() - 3600 * 1000 * 24)

    // 			return [date1, date2];
    // 		}
    // 	},
    // 	{
    // 		text: '3일전',
    // 		onClick: () => {
    // 			const start = new Date();
    // 			const end = new Date();

    // 			start.setTime(start.getTime() - 3 * 24 * 3600 * 1000);

    // 			const date = [start, end];
    // 			// emit(date);

    // 			return date;
    // 		}
    // 	},
    // 	{
    // 		text: '3일후',
    // 		onClick: () => {
    // 			const start = new Date();
    // 			const end = new Date();

    // 			end.setTime(end.getTime() + 3 * 24 * 3600 * 1000);

    // 			const date = [start, end];

    // 			return date;
    // 		}
    // 	},
    // 	{
    // 		text: '7일전',
    // 		onClick: () => {
    // 			const start = new Date();
    // 			const end = new Date();

    // 			start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);

    // 			const date = [start, end];

    // 			return date;
    // 		}
    // 	},
    // ],
  }),
  methods: {
    // selectNextThreeDay(emit) {
    // 	const start = new Date();
    // 	const end = new Date();
    // 	end.setTime(end.getTime() + 3 * 24 * 3600 * 1000);
    // 	const date = [start, end];
    // 	emit(date);
    // },
    getFormatDate(date) {
      var year = date.getFullYear();				//yyyy
      var month = (1 + date.getMonth());			//M
      month = month >= 10 ? month : '0' + month;	//month 두자리로
      var day = date.getDate();
      day = day >= 10 ? day : '0' + day;			//day 두자리로
      return year + '-' + month + '-' + day;
    },
    changeDateGubun(date) {
      this.$emit('toParent:selectDateGubun', date);
    },
    changeFromDate(date) {
      this.$emit('toParent:setFromDate', date);
    },
    changeToDate(date) {
      this.$emit('toParent:setToDate', date);
    },
    keyupFromDate(date) {
      this.$refs.fromDate.$refs.input.value = this.validDate(date);

    },
    keyupToDate(date){
    this.$refs.toDate.$refs.input.value = this.validDate(date);
    },
    validDate(date){
      let RegNotNum = /[^0-9]/g;
      var vDate = date.target.value;

      vDate = vDate.replace(RegNotNum, ''); // 숫자만 남기기

      if (vDate == "" || vDate == null || vDate.length < 5) {	
        return vDate;
      }

      if(vDate.length > 8){
        vDate = vDate.substring(0, 8);
      }

      var DataFormat;
      var RegPhonNum;

      // 날짜 포맷(yyyy-mm-dd) 만들기
      if (vDate.length <= 6) {
        DataFormat = "$1-$2"; // 포맷을 바꾸려면 이곳을 변경
        RegPhonNum = /([0-9]{4})([0-9]+)/;
      } else if (vDate.length <= 8) {
        DataFormat = "$1-$2-$3"; // 포맷을 바꾸려면 이곳을 변경
        RegPhonNum = /([0-9]{4})([0-9]{2})([0-9]+)/;	
      }

      vDate = vDate.replace(RegPhonNum, DataFormat);

      return vDate;

    },
  },
  mounted() {
    const start = new Date();
    start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);

    const end = new Date();

    this.fromDate = this.getFormatDate (start);	
    this.toDate = this.getFormatDate(end);

    this.$emit('toParent:setFromDate', this.fromDate);
    this.$emit('toParent:setToDate', this.toDate);
  },
}
</script>