<template>
	<div class="w3-modal w3-animate-opacity" v-if="$attrs.visible" style="display:block">
		<div class="w3-modal-content w3-card-4 w3-animate-zoom pt20 pb30 pr20 pl25" style="max-width:700px">
			<div class="w3-center">
				<span @click="closePopup" class="w3-button w3-xlarge w3-transparent w3-display-topright fs30 bold lh25" title="Close Modal">×</span>
			</div>
			<form class="form-group" action="">
				<h4 class="login-pop-h4 tx_cnt">신용카드 등록/관리</h4>
				<div class="w3-section mr10 ml15">
					<div class="radio credit-table-block tx_cnt pt20 pb20 mb10">
						<!-- 카드사,인증방식,인증서명 --> 
						<table class="table0">
							<caption>신용카드 등록사항</caption>
							<colgroup>
								<col style="width:100px">
								<col style="width:100px">
								<col style="width:100px">
								<col style="width:100px">
								<col style="width:100px">
								<col>
							</colgroup>
							<tr>
								<th class="tx_rt"><i class="fas fa-caret-right fs14"></i> 카드사</th>
								<td class="tx_lf">{{$attrs.cardNm}}</td>
								<th class="tx_rt"><i class="fas fa-caret-right fs14"></i> 인증방식</th>
								<td class="tx_lf">{{$attrs.authType == 'CERT'? '인증서':'ID/PW'}}</td>
								<th class="tx_rt"><i class="fas fa-caret-right fs14"></i> 사업자유형</th>
								<td class="tx_lf">{{$attrs.bizKind == "PERSON" ? '개인':'법인'}}</td>
							</tr>
							<tr>
								<th class="tx_rt"><i class="fas fa-caret-right fs14"></i> {{$attrs.authType == 'CERT'?'인증서명':'웹 아이디'}}</th>
								<td class="tx_lf" colspan="3">{{$attrs.authNm}}</td>
							</tr>
						</table>
					</div>
					<!-- 등록 카드 리스트 -->
					<!-- Button -->
					<div class="tx_rt pb10">
						<button type="button" @click="addrow()" class="btn btn-block btn-primary btn-sm w60">추가</button>
					</div>
					<table class="table01">
						<caption>등록 신용카드 표</caption>
						<colgroup>                  
							<col v-for="(item, index) in $attrs.heads" :key="index" :style="item.style">
						</colgroup>
						<thead>
							<tr>
								<th v-for="(item, index) in $attrs.heads" :key="index" class="tx_cnt"  v-html="item.col">{{item.col}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in bodysData" :key="index">
								<!-- 체크박스 -->
								<td v-if="item.idx != ''" class="tx_cnt"><label><input type="checkbox" :value="item.idx" v-model="checkedList"></label></td>
								<td v-else class="tx_cnt"></td>
								<!-- 카드번호 -->
								<td v-if="item.cardNo != ''" class="tx_cnt">{{ item.cardNo }}</td>
								<td v-else class="tx_cnt">
									<input :id="'cardOne'+index" class="form-control form-control-table w60 tx_cnt" type="text" maxlength="4"  @keypress="onlyNumbers" @keyup="nextSibiling($event, index)" placeholder="입력"> -
									<input :id="'cardTwo'+index" class="form-control form-control-table w60 tx_cnt" type="text" maxlength="4" @keypress="onlyNumbers" @keyup="nextSibiling($event, index)" placeholder="입력"> -
									<input :id="'cardThree'+index" class="form-control form-control-table w60 tx_cnt" type="text" maxlength="4" @keypress="onlyNumbers" @keyup="nextSibiling($event, index)" placeholder="입력"> -
									<input :id="'cardFour'+index" class="form-control form-control-table w60 tx_cnt" type="text" maxlength="4" @keypress="onlyNumbers" @keyup="nextSibiling($event, index)" placeholder="입력">
								</td>
								<!-- 카드번호 end -->
								<!-- 별칭 -->
								<td v-if="item.cardAlias != ''" class="tx_cnt">{{item.cardAlias}}</td>
								<td v-else class="tx_cnt">
									<input :id="'cardAlias'+index" class="form-control form-control-table w80 tx_cnt" type="text" placeholder="입력">
								</td>
								<!-- 별칭 end -->
								<!-- 상태 -->
								<td v-if="item.status == ''" class="tx_cnt">
									추가		
								</td>
								<td v-else-if="item.status == 'VALID'" class="tx_cnt txt-primary">
									{{statusMsg(item.status)}}
								</td>
								<td v-else class="tx_cnt txt-danger">
									{{statusMsg(item.status)}}
								</td>
                <input :id="'cdNo' + index" v-show="false" >
								<!-- 상태 end -->
								<td v-if="item.idx != ''" class="tx_cnt"><button type="button" @click="deleteCard(item, index)" class="btn btn-default btn-sm">삭제</button></td>
								<td v-else class="tx_cnt"><button type="button" @click="saveCard(index)" class="btn btn-black btn-sm">저장</button></td>
							</tr>
						</tbody>
					</table>
					<!-- //등록 카드 리스트 -->
					<!-- Button -->
					<div class="btnwrap clear pt10">
						<button @click="verifyCardList" type="button" class="btn btn-block btn-danger mr10 w115">검증</button>
						<button @click="closePopup" type="button" class="btn btn-block btn-default w115">취소</button>
					</div>
				</div>
			</form>
		</div>
    <div class="w3-container">
      <base-card-select
          v-if="isVisibleCardSelect"
          :authIdx="idx"
          :cardNo="cardNo"
          @close="(s) => closeCardSelectPopup(s)"
          @selectItem="(item) => placeCardNumber(item)"/>
    </div>
	</div>
</template>
<script>

export default {
	name: 'CreditCardRegPopup',
	props:{
		bodys: Array,
		idx: String,
	},
	data:()=>({
		prefix:"api/auth/card",

		defaultClass: "tx_cnt",
		checkedList:[],

		addRowState: true,
    currAddedIndex: -1, // 저장 요청할 row의 배열 index
    cardNo:"",
    cardAlias: "",
    cdNo:"",


    //popup control
    isVisibleCardSelect: false
	}),
	computed:{
		bodysData: function(){
			return this.bodys;
		}
	},
	watch:{
		bodys(val){
			if(val.length == 1 && val[0].idx == ""){
				this.addRowState = false;
			}
		}
	},
	methods: {
		addrow(){
			if(!this.addRowState) return false;
			let obj = {
					idx: "",
					cardNo:"",
					cardAlias:"",
					status:"",
					statusDesc:"",
				};
			this.bodysData.push(obj);
			//저장하고나서 true 로 풀어주자
			this.addRowState = false;

		},
		deleteCard(row, index){
			let delYn = confirm("카드정보를 삭제하시겠습니까?");
			if(!delYn) return false;
			if(row.idx == '') {
				alert("카드정보를 가져오지 못했습니다.");
				return false;
			}
			let param = {};
			param.idx = row.idx;
			this.$cAxios.post(this.prefix + "/card/delete", JSON.stringify(param),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then(()=>{
				alert("삭제되었습니다.");
				this.removeRow(index);
			});
		},
		removeRow(index){
			this.bodysData.splice(index, 1);
		},
		saveCard(index){
      // index 저장 및 저장에 쓰일 공통 param setting
      this.currAddedIndex = index;

			let cardOne = document.getElementById("cardOne"+this.currAddedIndex).value;
			let cardTwo = document.getElementById("cardTwo"+this.currAddedIndex).value;
			let cardThree = document.getElementById("cardThree"+this.currAddedIndex).value;
			let cardFour = document.getElementById("cardFour"+this.currAddedIndex).value;

			this.cardNo = cardOne+cardTwo+cardThree+cardFour;
			if(this.cardNo.length <15){
				alert("카드번호를 확인해주세요");
				return false;
			}

			this.cardAlias = document.getElementById("cardAlias"+this.currAddedIndex).value;
			if(this.cardAlias.length < 1){
				alert("카드 별칭정보를 등록해주세요");
				return false;
			}

      if(this.$attrs.authType === "ID" && this.$attrs.cardNm === "롯데카드"){
        // 롯데카드 등록
        this.registLotteCard();
      }else{
        // 카드 등록
        this.registCard();
      }

		},
    registCard(){
      let param = {
        idx: this.idx,
        scrapFinances: [
          {
            financeNo: this.cardNo,
            financeAlias: this.cardAlias,
            cdNo: this.cdNo
          }
        ]
      };

      return this.$cAxios.post(this.prefix+"/finance/regist", param, {
        headers : {
          'Content-Type':'application/json'
        }
      }).then((res)=>{
        if(res.data != null){
          alert("등록되었습니다.");

          //등록 완료한 row를 삭제하고
          this.removeRow(this.currAddedIndex);

          //저장한 카드정보를 넣어주자
          let obj = {
            idx: res.data.idx.toString(),
            cardNo: res.data.financeNo,
            cardAlias:res.data.financeAlias,
            status:res.data.status,
            statusDesc:res.data.statusDesc,
          };
          this.bodysData.push(obj);
          //저장하고나서 true 로 풀어주자
          this.addRowState = true;
        }
      }).catch((err)=>{
        if(err.response){
          alert(err.response.data.message);
        }
      });
    },
		verifyCardList(){
			let checkedList = this.checkedList;
			if(checkedList.length < 1) {
				alert("검증하실 카드 목록을 선택해 주십시오.");
				return false;
			}
			let cardList = new Array();
			for(var i = 0 ; i < checkedList.length; i++){
				let cardInfo = {};
				cardInfo.idx = checkedList[i];
				cardList.push(cardInfo);
			}
			this.$cAxios.post(this.prefix+"/finance/list/verify", JSON.stringify(cardList),{
				headers:{
					'Content-Type':'application/json'
				}
			}).finally(()=>{
				this.getCardList();
			});

		},
		getCardList(){
			let authIdx = this.idx;
			let param = {};
			param.idx = authIdx;
			this.$cAxios.post(this.prefix+"/card/list/inquriy",JSON.stringify(param),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then((res)=>{
				if(res.data != null){
					let cardList = new Array();
					if(res.data.scrapFinances == null){
						this.$set(this.cardInfoTable_attrs,'bodys',cardList);
						return;
					}
					this.bodysData.splice(0);

					let inquriyCardList = res.data.scrapFinances;
					for(var i = 0; i < inquriyCardList.length; i++){
						let cardInfo = {};
						cardInfo.idx = inquriyCardList[i].idx.toString();
						cardInfo.cardNo = inquriyCardList[i].financeNo;
						cardInfo.cardAlias = inquriyCardList[i].financeAlias;
						cardInfo.status = inquriyCardList[i].status;
						cardInfo.statusDesc = inquriyCardList[i].statusDesc;
						// cardList.push(cardInfo);
						
						this.bodysData.push(cardInfo);
					}
					// this.$set(this.bodysData,0,cardList);
				}
			}).finally(()=>{
				
			});
		},
		statusMsg: function(valid){
			if(valid == "VALID"){
				return "정상"
			}else{
				return "실패";
			}
		},
		onlyNumbers: function($event){
			let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
			if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
				$event.preventDefault();
			}
		},
		nextSibiling: function(event, index){
			if(event.target.value.length == 4){
				if(event.target.id == "cardFour"+index){
					document.getElementById("cardAlias"+index).focus();
				}
				
				let id = event.target.id;
				document.getElementById(id).nextSibling.nextSibling.focus();
			}
		},
		closePopup() {
      // 닫기 전 변수 초기화
			this.addRowState = true;
			this.$emit('toParent:visible', false)
		},
    registLotteCard(){
      let param = {
        idx: this.idx,
        financeInfo: {financeNo: this.cardNo}
      };
      let _this = this;
      this.$cAxios.post(this.prefix + "/list/lotte", param,{
        headers:{
          'Content-Type':'application/json'
        }
      })
      .then((res) => {
        if(res.data.length == 0){
          // 해당 카드를 찾을 수 없음
          alert("카드정보를 찿을수 없습니다 카드번호를 확인해주세요");
        }else if(res.data.length == 1){
          _this.cdNo = res.data[0].cdNo;
          _this.registCard();
        }else{
          // 카드선택 팝업 오픈
          this.isVisibleCardSelect = true;
        }
      })

    },
    closeCardSelectPopup(s){  // 카드목록 팝업 닫기
      this.isVisibleCardSelect = s;
      this.addRowState = true;
      this.bodys.pop(); // 추가한 row 제거
    },
    placeCardNumber(item){  // 조회된 카드중 선택하였을때
      this.cdNo = item.cdNo;
      this.registCard();
      this.isVisibleCardSelect = false;
    }
	},
}
</script>