import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [		//	URL - Component 맵핑		//	routes 각 레코드별 내부에 NavigationGuard의 beforeEnter로 라우트별로 보호 기능도 넣을 수 있다.?
		{
			name: 'first_access',
			path: '/',
			component: () => import('@/views/layouts/Index'),
			children: [
				{
					name: 'auth_hometax_user',
					path: 'auth/hometax/user',
					meta: {
						menu : '홈택스(일반사용자)',
						upper_menu: '인증정보 관리'
					},
					component: () => import('@/views/pages/auth/AuthHometaxUser'),
				},
				{
					name: 'auth_hometax_agent',
					path: 'auth/hometax/agent',
					meta: {
						menu : '홈택스(세무대리인)',
						upper_menu: '인증정보 관리'
					},
					component: () => import('@/views/pages/auth/AuthHometaxAgent'),
				},
				{
					name: 'auth_credit',
					path: 'auth/credit',
					meta: {
						menu : '여신금융협회',
						upper_menu: '인증정보 관리'
					},
					component: () => import('@/views/pages/auth/AuthCredit'),
				},
				{
					name: 'auth_card',
					path: 'auth/card',
					meta: {
						menu : '신용카드',
						upper_menu: '인증정보 관리'
					},
					component: () => import('@/views/pages/auth/AuthCard'),
				},
				{
					name: 'auth_bank',
					path: 'auth/bank',
					meta: {
						menu : '은행',
						upper_menu: '인증정보 관리'
					},
					component: () => import('@/views/pages/auth/AuthBank'),
				},
				{
					name: 'auth_pos',
					path: 'auth/pos',
					meta: {
						menu : '카드단말기',
						upper_menu: '인증정보 관리'
					},
					component: () => import('@/views/pages/auth/AuthPos'),
				},
				{
					name: 'publiccorporation',
					path: 'auth/publiccorporation',
					meta: {
						menu : '건강보험/연금/요양기관',
						upper_menu: '인증정보 관리'
					},
					component: () => import('@/views/pages/auth/AuthPublicCorporation'),
				},
				{
					name: 'scrap_useMng',
					path: 'scrap/useMng',
					meta: {
						menu : '스크랩핑 사용여부 관리',
						upper_menu: '스크랩핑 관리'
					},
					component: () => import('@/views/pages/scraping/ScrapingUseMng'),
				},
				{
					name: 'scrap_history',
					path: 'scrap/history',
					meta: {
						menu : '스크랩핑 결과조회',
						upper_menu: '스크랩핑 관리'
					},
					// props: true,	//router로 params 전달할 때, props true로 해놓은 상태면 데이터가 props에도 전달된다.
					component: () => import('@/views/pages/scraping/ScrapingHistory'),
				},
				{
					name: 'scrap_manual',
					path: 'scrap/manual',
					meta: {
						menu : '스크랩핑 수동실행',
						upper_menu: '스크랩핑 관리'
					},
					component: () => import('@/views/pages/scraping/ScrapingManual'),
				},
				{
					name: 'linkedsys_common',
					path: 'linkedsys/common',
					meta: {
						menu : '공통 설정',
						upper_menu: '연계시스템 관리'
					},
					component: () => import('@/views/pages/linkedsystem/CommonMng'),
				},
				{
					name: 'undefined_path',
					path: '/error/404',
					meta: {
						menu : '404',
						upper_menu: 'ERROR'
					},
					component: () => import('@/views/pages/common/error/ErrorPageNotFound404'),
				},
			]
		}
	]
});

//	NavigationGuard 설정
//	1. beforeEach
router.beforeEach((to, from, next) => {

	if (to.name == null) {
		// 미등록 path로 접근할 경우 404로 이동
		next('/error/404');
	} else {
		// 등록한 path로 접근할 경우 그대로 이동
		next();
	}
});
// router.beforeResolve((to, from, next) => {
	// console.log('beforeResolve');
	// Global Guard 등록 가능
	// beforeEach와 유사한데,
	// 모든 컴포넌트 카드와 비동기 라우트 컴포넌트를 불러온 후 네비게이션 가드를 확인하기 전에 호출된다는 차이가 있다.?
// });

// router.onError((err) => {
	//NavigationGuard보다 나중이거나....역할이 다르거나.......
// 	console.log(err.data);
// })
export default router;