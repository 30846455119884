// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// Module
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios';
import { cAxios } from '@/utils/axios.js' //Axios에 progress 표시 추가한 커스텀 axios
import ValidationProvider from '@/utils/validation.js';
import {ValidationObserver} from 'vee-validate';
import * as VeeValidate from 'vee-validate';

// vuex store js 불러오기
import store from './store/index'

Vue.component('ValidationProvider',ValidationProvider);
Vue.component('ValidationObserver',ValidationObserver);
Vue.use(VeeValidate); 

// axios 설정
//기존 axios
Axios.defaults.baseURL = "/";
// Vue.prototype.$axios = Axios;
//커스텀 axios
Vue.prototype.$cAxios = cAxios;
Vue.config.productionTip = false
// 공통 컴포넌트 설정
import './plugins/base'
// Calendar DatePicker 설정
import './plugins/datepicker'



new Vue({
  router,
  store: store,   // 뷰 인스턴스의 store 속성에 연결
  render: h => h(App)
}).$mount('#app')
