import { extend ,ValidationProvider} from 'vee-validate';
import { required, min } from 'vee-validate/dist/rules';

extend('required',{
	...required,
	message : '필수 입력항목입니다.',
	validate(value){
		return{
			required: true,
			valid: ['',null, undefined].indexOf(value) === -1
		};
	},
	computesRequired: true,
});

extend('min',{
	...min,
	validate(value, { min }){
		if(value.length >= min){
			return true;
		}
		return '{_field_}는 {min} 글자 이상이어야 합니다.';
	},
	params:['min']
});


export default ValidationProvider;