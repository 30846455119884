import Axios from 'axios';
import {eventHub} from '@/utils/eventhub.js'
import NProgress from 'nprogress';
import '@/css/nprogress.css';

function createAxios () {
  //헤더정보에 넣을게 있는지 체크 (토큰 등...)
  const axios = Axios.create({
    baseURL : "/",
    withCredentials: false, //크로스 사이트 접근 제어 허용 
    //auth:{} //token 권한 체크
  });


  //request 인터셉터
  axios.interceptors.request.use((config) => {
    eventHub.$emit('before-request');
	NProgress.start();
    return config;
  },(error)=>{
	NProgress.done();
    eventHub.$emit('request-error');
    return Promise.reject(error);
  });

  //response 인터셉터
  axios.interceptors.response.use((response) => {
//	console.log(response.config.url);
	NProgress.done();
    eventHub.$emit('after-response');
    return response;
  },(error) => {
	NProgress.done();
    eventHub.$emit('response-error');


    // 2021-02-17 : error.response가 undefuned가 아닐 경우에 401체크하도록 추가
    if (error.response && error.response.status == 401) {
      // 세션 만료 alert : 재접속 안내
      alert(error.response.data.message);
    }
    return Promise.reject(error);
  });

  return axios;
}

export const cAxios = createAxios();