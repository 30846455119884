<template>
	<!-- Login Modal Popup 로그인 -->
	<div id="id01" v-if="visible" class="w3-modal w3-animate-opacity" style="display:block">
		<div class="w3-modal-content w3-card-4 w3-animate-zoom pt20 pb30 pr20 pl25" style="max-width:330px">
			<div class="w3-center">
				<span @click="closePopup" class="w3-button w3-xlarge w3-transparent w3-display-topright fs30 bold lh25" title="Close Modal">×</span>
			</div>
			<validation-observer v-slot="{ handleSubmit }">
			<form class="form-group" action="">
				<h4 class="login-pop-h4 tx_cnt">인증정보</h4>
				<div class="w3-section mr10 ml15">
					<validation-provider rules="required" v-slot="{errors}">
						<label class="login-label icon-user"><span class="hidden">아이디</span></label>
						<input class="form-control mb10 login-input" type="text"  v-model.trim="id" placeholder="아이디를 입력하세요" :readonly="readonlyYN" name="usrname" required />
						<span v-if="errors[0]" class="help-block txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</span>
					</validation-provider>
					<validation-provider rules="required" v-slot="{errors}">
						<label class="login-label icon-pw"><span class="hidden">비밀번호</span></label>
						<input class="form-control mb10 login-input" type="password"  v-model.trim="pwd" placeholder="비밀번호를 입력하세요" name="password" required />
						<span v-if="errors[0]" class="help-block txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</span>
					</validation-provider>
				</div>
				<div class="btnwrap clear pt20">
					<button @click="handleSubmit(submit)" type="button" class="btn btn-block btn-primary mr10 w115">확인</button>
					<button @click="closePopup" type="button" class="btn btn-block btn-default  w115">취소</button>
				</div>
			</form>
			</validation-observer>
		</div>
	</div>
	<!-- //Login Modal Popup -->
</template>
<script>
export default {
	name: 'LoginIdPwdPopup',
	
	props: [
		'propId',
		'propPwd',
		'propPopupType',
		'visible'
	],
	data: function () {
		return{
			id : "",
			pwd : "",

			required: false,
		}
	},
	computed:{
		readonlyYN(){
			if(this.propPopupType == "manageNo") {return true;}
			else{return false;}
		}
	},
	watch:{
		visible: function(val){
			if(val){
				if(typeof this.propId != "undefined" && this.propId != ""){
					this.id = this.propId;
				}
				if(typeof this.propPwd != "undefined" && this.propPwd != ""){
					this.pwd = this.propPwd;
				}
			}
			this.readonlyYN;
		}
	},
	methods: {
		closePopup() {
			this.id = "";
			this.pwd = "";
			this.$emit('toParent:visible', false)
		},
		submit() {
			this.$emit('toParent:submit',this.propPopupType, this.id, this.pwd)
		}
	}
}
</script>