<template>
	<!-- <div class="contents"> -->
		<div class="card shadow">
			<div class="card-body mt10">
				<!-- 인증정보 등록 안내 -->
				<div class="card-grey">
					<div class="info-block">
						<span class="info-title">{{ infoTitle }}</span>	<!-- 인증정보가 등록되지 않았습니다.-->
						<span class="help-block fs16 text-gray3">{{ text1 }}<br/>{{ text2 }}</span>	<!-- 신규등록 버튼을 클릭하여 인증정보를 등록 하세요.	//	(세금)계산서, 현금영수증 등을 수집하기 위한 인증정보를 등록합니다. -->
					</div>
				</div>
				<!-- //인증정보 등록 안내 -->

				<!-- Button	-->
				<slot name="slot_btns"/>
			</div>
		</div>
	<!-- </div> -->
</template>
<script>

export default {
	name: 'TextContents',

	props: {
		infoTitle: {
			type: String,
			required: true,
			default: '',
		},
		text1: {
			type: String,
			required: false,
			default: '',
		},
		text2: {
			type: String,
			required: false,
			default: '',
		},
	},
	data: () => ({
		// Variable
		
		// Components attrs
	}),
	methods: {
		
	},
	
}
</script>