<template>
	<div class="w3-modal w3-animate-opacity" v-if="$attrs.visible" style="display:block">
		<div class="w3-modal-content w3-card-4 w3-animate-zoom pt20 pb30 pr20 pl25" style="max-width:700px">
			<div class="w3-center">
				<span @click="closePopup" class="w3-button w3-xlarge w3-transparent w3-display-topright fs30 bold lh25" title="Close Modal">×</span>
			</div>
			<form class="form-group" action="">
				<h4 class="login-pop-h4 tx_cnt">계좌 등록/관리</h4>
				<div class="w3-section mr10 ml15">
					<div class="radio credit-table-block tx_cnt pt20 pb20 mb10">
						<!-- 은행사,인증방식,인증서명 --> 
						<table class="table0">
							<caption>계좌 등록사항</caption>
							<colgroup>
								<col style="width:100px">
								<col style="width:100px">
								<col style="width:100px">
								<col style="width:100px">
								<col style="width:100px">
								<col>
							</colgroup>
							<tr>
								<th class="tx_rt"><i class="fas fa-caret-right fs14"></i> 은행사</th>
								<td class="tx_lf">{{$attrs.bankNm}}</td>
								<th class="tx_rt"><i class="fas fa-caret-right fs14"></i> 인증방식</th>
								<td class="tx_lf">{{$attrs.authType == 'CERT'? '인증서':'ID/PW'}}</td>
								<th class="tx_rt"><i class="fas fa-caret-right fs14"></i> 사업자유형</th>
								<td class="tx_lf">{{$attrs.bizKind == "PERSON"? '개인':'법인'}}</td>
							</tr>
							<tr>
								<th class="tx_rt"><i class="fas fa-caret-right fs14"></i> {{$attrs.authType == 'CERT'?'인증서명':'웹 아이디'}}</th>
								<td class="tx_lf" colspan="3">{{$attrs.authNm}}</td>
							</tr>
						</table>
					</div>
					<!-- 등록 계좌 리스트 -->
					<!-- Button -->
					<div class="tx_rt pb10">
						<button type="button" @click="addrow()" class="btn btn-block btn-primary btn-sm w60">추가</button>
					</div>
					<table class="table01">
						<caption>등록 계좌 표</caption>
						<colgroup>                  
							<col v-for="(item, index) in $attrs.heads" :key="index" :style="item.style">
						</colgroup>
						<thead>
							<tr>
								<th v-for="(item, index) in $attrs.heads" :key="index" class="tx_cnt"  v-html="item.col">{{item.col}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in bodysData" :key="index">
								<!-- 체크박스 -->
								<td v-if="item.idx != ''" class="tx_cnt"><label><input type="checkbox" :value="item.idx" v-model="checkedList"></label></td>
								<td v-else class="tx_cnt"></td>
								<!-- 계좌번호 -->
								<td v-if="item.accountNo != ''" class="tx_cnt">{{item.accountNo}}</td>
								<td v-else class="tx_cnt">
									<input :id="'accountNo'+index" class="form-control form-control-table w280 tx_cnt" maxlength="15" type="text" @keypress="onlyNumbers" placeholder="입력">
								</td>
								<!-- 계좌번호 end -->
								<!-- 별칭 -->
								<td v-if="item.accountAlias != ''" class="tx_cnt">{{item.accountAlias}}</td>
								<td v-else class="tx_cnt">
									<input :id="'accountAlias'+index" class="form-control form-control-table w80 tx_cnt" type="text" placeholder="입력">
								</td>
								<!-- 별칭 end -->
								<!-- 상태 -->
								<td v-if="item.status == ''" class="tx_cnt">
									추가		
								</td>
								<td v-else-if="item.status == 'VALID'" class="tx_cnt txt-primary">
									{{statusMsg(item.status)}}
								</td>
								<td v-else class="tx_cnt txt-danger">
									{{statusMsg(item.status)}}
								</td>
								<!-- 상태 end -->
								<td v-if="item.idx != ''" class="tx_cnt"><button type="button" @click="deleteAccount(item, index)" class="btn btn-default btn-sm">삭제</button></td>
								<td v-else class="tx_cnt"><button type="button" @click="saveAccount(index, idx)" class="btn btn-black btn-sm">저장</button></td>
							</tr>
						</tbody>
					</table>
					<!-- //등록 계좌 리스트 -->
					<!-- Button -->
					<div class="btnwrap clear pt10">
						<button @click="verifyAccountList" type="button" class="btn btn-block btn-danger mr10 w115">검증</button>
						<button @click="closePopup" type="button" class="btn btn-block btn-default w115">취소</button>
						<!--button onclick="" type="button" class="btn btn-block btn-primary w115">확인</button-->  
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>

export default {
	name: 'AccountRegPopup',
	props:{
		bodys: Array,
		idx: String,
	},
	data:()=>({
		prefix:"api/auth/bank",

		checkedList:[],

		addRowState: true,
	}),
	computed:{
		bodysData: function(){
			return this.bodys;
		}
	},
	watch:{
		bodys(val){
			if(val.length == 1 && val[0].idx == ""){
				this.addRowState = false;
			}
		}
	},
	methods: {
		addrow(){
			if(!this.addRowState) return false;
			let obj = {
					idx: "",
					accountNo:"",
					accountAlias:"",
					status:"",
					statusDesc:"",
				};
			this.bodysData.push(obj);
			this.addRowState = false;
		},
		deleteAccount(row, index){
			let delYn = confirm("계좌정보를 삭제하시겠습니까?");
			if(!delYn) return false;
			if(row.idx == '') {
				alert("계좌정보를 가져오지 못했습니다.");
				return false;
			}
			let param = {};
			param.idx = row.idx;
			this.$cAxios.post(this.prefix + "/account/delete", JSON.stringify(param),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then(()=>{
				alert("삭제되었습니다.");
				this.removeRow(index);
			});
		},
		removeRow(index){
			this.bodysData.splice(index, 1);
		},
		saveAccount(index, authIdx){
			let accountNo = document.getElementById("accountNo"+index).value;

			if(accountNo.length < 1){
				alert("계좌번호를 확인해주세요");
				return false;
			}

			let accountAlias = document.getElementById("accountAlias"+index).value;
			if(accountAlias.length < 1){
				alert("계좌 별칭정보를 등록해주세요");
				return false;
			}
			
			let accountList = new Array();
			let accountInfo = {};
			accountInfo.financeNo = accountNo;
			accountInfo.financeAlias = accountAlias;
			accountList.push(accountInfo);

			let param = {};
			param.idx = authIdx;
			param.scrapFinances = accountList;

			this.$cAxios.post(this.prefix+"/finance/regist", JSON.stringify(param), {
				headers : {
					'Content-Type':'application/json'
				}
			}).then((res)=>{
				if(res.data != null){
					alert("등록되었습니다.");

					this.removeRow(index);
					let obj = {
						idx: res.data.idx.toString(),
						accountNo: res.data.financeNo,
						accountAlias:res.data.financeAlias,
						status:res.data.status,
						statusDesc:res.data.statusDesc,
					};
					this.bodysData.push(obj);

					this.addRowState = true;
				}
			}).catch((err)=>{
				alert(err.response.data.message);
			});
		},
		verifyAccountList(){
			let checkedList = this.checkedList;
			if(checkedList.length < 1) {
				alert("검증하실 계좌를 선택해 주십시오.");
				return false;
			}
			let accountList = new Array();
			for(var i = 0 ; i < checkedList.length; i++){
				let accountInfo = {};
				accountInfo.idx = checkedList[i];
				accountList.push(accountInfo);
			}
			this.$cAxios.post(this.prefix+"/account/list/verify", JSON.stringify(accountList),{
				headers:{
					'Content-Type':'application/json'
				}
			}).finally(()=>{
				alert("완료되었습니다.");
				this.getAccountList();
			});

		},
		getAccountList(){
			let authIdx = this.idx;
			let param = {};
			param.idx = authIdx;
			this.$cAxios.post(this.prefix+"/account/list/inquriy",JSON.stringify(param),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then((res)=>{
				if(res.data != null){
					this.bodysData.splice(0);

					if(res.data.scrapFinances == null){
						this.addrow();
						return;
					}

					let inquriyAccountList = res.data.scrapFinances;
					for(var i = 0; i < inquriyAccountList.length; i++){
						let accountInfo = {};
						accountInfo.idx = inquriyAccountList[i].idx.toString();
						accountInfo.accountNo = inquriyAccountList[i].financeNo;
						accountInfo.accountAlias = inquriyAccountList[i].financeAlias;
						accountInfo.status = inquriyAccountList[i].status;
						accountInfo.statusDesc = inquriyAccountList[i].statusDesc;
						
						this.bodysData.push(accountInfo);
					}
				}
			}).finally(()=>{
				
			});
		},
		statusMsg: function(valid){
			if(valid == "VALID"){
				return "정상"
			}else{
				return "실패";
			}
		},
		onlyNumbers: function($event){
			let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
			if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
				$event.preventDefault();
			}
		},
		closePopup() {
			this.addRowState = true;
			this.$emit('toParent:visible', false)
		},
	},
}
</script>