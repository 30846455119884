import { cAxios } from '@/utils/axios.js'

const AUTH_API_URL = "/api/user/role"

class AuthService {

	authenticate() {
		return cAxios
			.get(AUTH_API_URL)
			.then(response => {
				//문제 없으면 세션 정보를 저장하기
				if (response.data.ROLE.length > 0) {
					// localStorage 에 저장
					localStorage.setItem('userRole', JSON.stringify(response.data))
				}
				// return값을 넘겨줘서 얘를 호출하는 auth.module에서 store에 저장!
				return response.data;

			}).catch(error => {
				console.log(error.response);
			});
	}

	// 세션 삭제 메서드를 여기에 선언해두고 쓸 필요가 있을까?
}

export default new AuthService();