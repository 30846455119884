<template>
	<!-- AuthHometaxAgent에서 영역 잡아줘야해서 모화면에서 div class="contents" 넣는게 나을듯함  
		<div class="contents"> -->
		<div class="card shadow">

			<slot name="slot_card_header" />

			<!-- 인증정보-홈택스(개인)-목록 -->
			<div class="card-body mt10">
				<h4 v-if="$attrs.tableTitle"><i class="fas fa-angle-double-right"></i>{{ tableTitle }}</h4>

				<table class="table01">
					<caption>{{ $attrs.caption }}</caption>
					<colgroup>
						<col v-for="(th, index) in $attrs.heads" :key="index" :style="th.style">
					</colgroup>
					<thead>
						<tr>
							<th v-for="(th, index) in $attrs.heads" :key="index" class="tx_cnt">{{ th.col }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in $attrs.items" :key="index">
							<td v-for="(item, index) in item.tds" :key="index" class="tx_cnt">{{ item.text }}</td>

							<!-- status 관련 td -->
							<td v-if="item.td_status == 'success'" class="tx_cnt txt-primary">정상</td>

							<!-- items.length가 1이냐 아니냐는 초기 디자인용. 테이블 row가 1일 경우 tooltip아닌 테이블 아래에 에러 메시지 띄우려는 목적이었음 -->
							<td v-else-if="item.td_status == 'failed' && $attrs.items.length == 1" class="tx_cnt txt-danger">검증실패</td>
							<td v-else-if="item.td_status == 'failed' && $attrs.items.length > 1" class="tx_cnt">
								<div class="tooltip">
									<span class="tooltip-cursor txt-danger">검증실패</span>
									<span class="tooltiptext form-group has-error tx_cnt"><i class="fa fa-times-circle-o has-error"></i>{{ $attrs.status_fail_msg[0].status_desc }}</span>
								</div>
							</td>
							<!-- //status 관련 td -->

							<td class="tx_cnt pl12">
								<!-- optional Buttons : 필요없으면 모화면에 div slot 안넣어도 된다. -->
								<p v-if="$attrs.optionalBtns == true && item.td_status == 'success'" class="btn-list01">
									<button type="button" class="btn btn-block btn-default btn-sm mr10">카드관리</button>
								</p>
								<p v-else-if="$attrs.optionalBtns == true && item.td_status == 'failed'" class="btn-list01"></p>


								<!-- //optional Buttons -->								
								<p class="btn-list02"><button type="button" @click="reRegister" class="btn btn-block btn-default btn-sm mr10">재등록</button></p>
								<p class="btn-list03"><button type="button" @click="deleteRow(index)" class="btn btn-block btn-default btn-sm">삭제</button></p>   
							</td>
						</tr>
					</tbody>
				</table>

				<span v-if="$attrs.status_fail_msg.length == 1" class="error-message tx_cnt"><i class="fa fa-times-circle-o has-error"></i>{{ $attrs.status_fail_msg[0].status_desc }}</span>
				
				<slot name="slot_pagination" />
			</div>
		</div>
	<!-- </div>	 -->
</template>
<script>

export default {
	name: 'TableContents',

	props: {
		// tableTitle: {
		// 	type: String,
		// 	required: false,
		// 	default: ''
		// },
		// caption: {
		// 	type: String,
		// 	required: false,
		// 	default: ''
		// },
		// heads: {
		// 	type: Array,
		// 	required: true,
		// 	default: undefined,
		// },
		// items: {
		// 	type: Array,
		// 	required: false,
		// 	default: undefined,
		// },
		// status_fail_msg: {
		// 	type: Array,
		// 	required: false,
		// 	default: undefined,
		// },
		// optionalBtns: {
		// 	type: Boolean,
		// 	required: false,
		// 	default: false
		// }

	},
	watch: {

	},
	data: () => ({
		// Variable
		
		// Components attrs

	}),
	methods: {
		openLoginIdPwdPopup() {
			this.$emit('toParent:visible1', true)
		},
		deleteRow(index){
			this.$emit('toParent:deleteCurrRow', index);
		},
		reRegister(){
			this.$emit('toParent:updateCert');
			this.$emit('toParent:updateCreditAuth');
		},
	},
}
</script>