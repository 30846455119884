<template>
  <div class="w3-container w3-show accd" v-if="agentVisible">
    <div data-v-25e2a221="" class="w3-center">
      <!-- 좌측 div -->
      <div class="form-control-inline" style="width: 45%; height: 500px;">
        <!-- 검색 영역 -->
        <div class="credit-table-block pl20 pt20 pb20 mb20" style="text-align:left;">
          <!-- 1 row -->
          <div class="tx_lt pb5 pt5">
            <!-- 사업자번호 -->
            <label class="ml10 mr20 bold"><i class="fas fa-caret-right fs14 mr5"></i>사업자번호</label>
            <input class="w135" type="text" placeholder="사업자번호" v-model="enteredLeftCorpRegNo"/>
            <label class="ml20 mr20 bold"><i class="fas fa-caret-right fs14 mr5"></i>생년월일</label>
            <input class="w120" type="text" placeholder="생년월일" v-model="enteredLeftBirthday"/>
            <label class="ml20 mr5 bold"><i class="fas fa-caret-right fs14 mr5"></i>유형</label>
            <base-select-box
                :class="selectBoxClass"
                :items="leftSeletBoxKindItem"
                @toParent:selectValue="val => leftKindChanged(val)" />
          </div>
          <!-- 2 row -->
          <div class="tx_lt pb5 pt5">
            <!-- 상호 -->
            <label class="mr60 bold"><i class="fas fa-caret-right fs14 ml10 mr5"></i>상호</label>
            <input class="w135" type="text" placeholder="상호 입력" v-model="enteredLeftCorpName"/>
            <!-- 대표자 -->
            <label class="ml12 mr32 bold"><i class="fas fa-caret-right fs14 ml10 mr5"></i>대표자</label>
            <input class="w120" type="text" placeholder="대표자 입력" v-model="enteredLeftReprName"/>

            <div class="btnwrap-right">
              <button type="button" class="btn btn-darkgrey ml15 mr20 w120" @click="searchLeftItems()">조회</button>
            </div>
          </div>
        </div>
        <!-- 전체 목록 테이블 -->
        <div class="" style="height:65%;overflow:auto">
          <table class="table01" style="border-collapse:collapse;">
            <colgroup>
              <col v-for="(item, index) in heads" :key="index" :style="item.style">
            </colgroup>
            <thead>
            <tr>
              <th style="position:sticky;top:0px;" v-for="(item, index) in heads" :key="index">{{ item.col }}</th>
            </tr>
            </thead>
            <tbody v-if="leftDivItems.length > 0">
            <tr v-for="(item, index) in leftDivItems" :key="index">
              <td><label><input type="checkbox" :value="index" v-model="checkedForAdd"></label></td>
              <td>{{ index + 1 }}</td>
              <td>{{ item.bizKind.displayName }}</td>
              <td v-if="item.corpRegNo != null">{{ formatCorpRegNo(item.corpRegNo) }}</td>
              <td v-else></td>
              <td>{{ item.resNo }}</td>
              <td>{{ item.corpName }}</td>
              <td>{{ item.reprName }}</td>
            </tr>
            </tbody>
            <tbody v-if="leftDivItems.length == 0">
            <tr>
              <td colspan="7">조회된 수임사 데이터가 없습니다.</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- 중앙 div -->
      <div class="form-control-inline pl10 pr10" style="width: 10%; vertical-align: middle;">
<!--        <button type="button" class="btn btn-primary ml15 mr20 mb10 w80" @click="addCorpList()">선택</button><br/>-->
<!--        <button type="button" class="btn btn-danger ml15 mr20 w80" @click="deleteCorpList()">해제</button>-->
        <button type="button" class="btn bg-primary txt-white mb15" @click="addCorpList()">선택 <i class="fas fa-angle-double-right ml3 txt-white"></i></button><br/>
        <button type="button" class="btn btn-black txt-white" @click="deleteCorpList()"><i class="fas fa-minus txt-white pr3"></i> 삭제</button>
      </div>
      <!-- 우측 div -->
      <div class="form-control-inline" style="width: 45%; height: 500px;">
        <!-- 검색 영역 -->
        <div class="credit-table-block pl20 pt20 pb20 mb20" style="text-align:left;">
          <!-- 1 row -->
          <div class="tx_lt pb5 pt5">
            <!-- 사업자번호 -->
            <label class="ml10 mr20 bold"><i class="fas fa-caret-right fs14 mr5"></i>사업자번호</label>
            <input class="w135" type="text" placeholder="사업자번호" v-model="enteredRightCorpRegNo"/>
            <label class="ml20 mr20 bold"><i class="fas fa-caret-right fs14 mr5"></i>생년월일</label>
            <input class="w120" type="text" placeholder="생년월일" v-model="enteredRightBirthday"/>
            <label class="ml20 mr5 bold"><i class="fas fa-caret-right fs14 mr5"></i>유형</label>
            <base-select-box
                :class="selectBoxClass"
                :items="rightSeletBoxKindItem"
                @toParent:selectValue="val => rightKindChanged(val)" />
          </div>
          <!-- 2 row -->
          <div class="tx_lt pb5 pt5">
            <!-- 상호 -->
            <label class="mr60 bold"><i class="fas fa-caret-right fs14 ml10 mr5"></i>상호</label>
            <input class="w135" type="text" placeholder="상호 입력" v-model="enteredRightCorpName" />
            <!-- 대표자 -->
            <label class="ml12 mr32 bold"><i class="fas fa-caret-right fs14 ml10 mr5"></i>대표자</label>
            <input class="w120" type="text" placeholder="대표자 입력" v-model="enteredRightReprName" />

            <div class="btnwrap-right">
              <button type="button" class="btn btn-darkgrey ml15 mr20 w120" @click="searchRightItems()">조회</button>
            </div>
          </div>
        </div>
        <!-- 선택 목록 테이블 -->
        <div class="" style="height:65%;overflow:auto">
          <table class="table01" style="border-collapse:collapse;">
            <colgroup>
              <col v-for="(item, index) in heads" :key="index" :style="item.style">
            </colgroup>
            <thead>
            <tr>
              <th style="position:sticky;top:0px;" v-for="(item, index) in heads" :key="index">{{ item.col }}</th>
            </tr>
            </thead>
            <tbody v-if="rightDivItems.length > 0">
            <tr v-for="(item, index) in rightDivItems" :key="index">
              <td><label><input type="checkbox" :value="item.idx" v-model="checkedForDelete"></label></td>
              <td>{{ index + 1 }}</td>
              <td>{{ item.bizKind.displayName }}</td>
              <td v-if="item.corpRegNo != null">{{ formatCorpRegNo(item.corpRegNo) }}</td>
              <td v-else> </td>
              <td>{{ item.resNo }}</td>
              <td>{{ item.corpName }}</td>
              <td>{{ item.reprName }}</td>
            </tr>
            </tbody>
            <tbody v-if="rightDivItems.length == 0">
            <tr>
              <td colspan="7">수임사를 선택해주세요</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>var leftKind;

import {mapState} from "vuex";
import SelectBox from "@/components/base/SelectBox";

export default {
  name: "AgentCorpChooseTable",
  props: {
    scrapId: {
      type: String,
      require: true,
      default: '',
    },
    parentCorpList: {
      type: Array,
      require: true,
      // default: [],
    },
    agentVisible: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data: () => ({
    heads: [
      {col: '선택', style: 'width:40px'},
      {col: '순번', style: 'width:50px'},
      {col: '유형', style: 'width:50px'},
      {col: '사업자번호', style: ''},
      {col: '주민번호', style: ''},
      {col: '상호', style: 'width:200px'},
      {col: '대표자', style: 'width:160px'},
    ],
    checkedForAdd: [],    //수임사 우측 선택영역에 넣기위해 사용
    checkedForDelete: [], // 수임수 우측 선택영역에서 삭제하기 위해 사용(사업장 idx로)
    leftDivItems: [],	    //script 제어용
    rightDivItems: [],	  //script 제어용
    rightSaveItems: [],	  //filter위한 보관용
    chooseCorpIdxList: [],

    //left 검색영역
    enteredLeftCorpRegNo: '',
    enteredLeftBirthday: '',
    enteredLeftCorpName: '',
    enteredLeftReprName: '',
    selectBoxClass: 'form-control pl5 form-control-inline w130 ml30',
    leftSeletBoxKindItem: [
      {key: 'ALL', value: '전체', selected: true},
      {key: 'CORP', value: '법인', selected: false},
      {key: 'PERSON', value: '개인', selected: false},
    ],
    leftKind: '',
    //right 검색영역
    enteredRightCorpRegNo: '',
    enteredRightBirthday: '',
    enteredRightCorpName: '',
    enteredRightReprName: '',
    rightSeletBoxKindItem: [
      {key: 'ALL', value: '전체', selected: true},
      {key: 'CORP', value: '법인', selected: false},
      {key: 'PERSON', value: '개인', selected: false},
    ],
    rightKind: '',

  }),
  methods: {
    //========== 선택한 수임사 추가 메서드.  ==========
    addCorpList() {
      for (var i = 0; i < this.checkedForAdd.length; i++) {
        let item = this.leftDivItems[this.checkedForAdd[i]];
        if (this.isNotDuplicate(item.idx)) {
          this.rightDivItems.push(item);
        }
      }
      this.rightSaveItems = this.rightDivItems;
      this.checkedForAdd = [];
    },
    //========== 선택한 수임사 삭제 메서드.  ==========
    deleteCorpList() {
      for (var i = 0; i < this.checkedForDelete.length; i++) {

        for (var j = 0; j < this.rightSaveItems.length; j++) {
          //  선택한 item과 사업자 번호가 일치할 경우 우측 right item에서 해당값 삭제
          if (this.checkedForDelete[i] == this.rightSaveItems[j].idx) {
            this.rightSaveItems.splice(j, 1);
          }
        }
      }
      // save에서 삭제 후, save목록 보여주기
      this.rightDivItems = this.rightSaveItems;
      this.checkedForDelete = [];
    },
    //========== 선택한 수임사 추가시, 우측 list 중복 체크 메서드.  ==========
    isNotDuplicate(index) {
      for (var k = 0; k < this.rightDivItems.length; k++) {
        if (this.rightDivItems[k].idx == index) {
          return false;
        }
      }
      return true;
    },
    //========== 좌측 검색 메서드.  ==========
    searchLeftItems() {
      //원래 데이터
      this.leftDivItems = this.parentCorpList;
      //filtering
      this.leftDivItems = this.doFilterLeftItems(this.leftDivItems);
    },
    searchRightItems() {
      //원래 데이터
      this.rightDivItems = this.rightSaveItems;
      //filtering
      this.rightDivItems = this.doFilterRightItems(this.rightDivItems);
    },
    //========== 좌측 검색 filter 메서드.  ==========
    doFilterLeftItems(items) {
      let arr = new Array();
      arr = items;

      if (this.enteredLeftCorpRegNo != null && this.enteredLeftCorpRegNo != '') {
        arr = arr.filter(item => {
          if (item.corpRegNo != null && item.corpRegNo !== '') {
            return item.corpRegNo.indexOf(this.enteredLeftCorpRegNo) > -1;
          }
        })
      }
      if (this.enteredLeftBirthday != null && this.enteredLeftBirthday != '') {
        arr = arr.filter(item => {
          if (item.resNo != null && item.resNo !== '') {
            let str = item.resNo.substr(0, 6);
            return str.indexOf(this.enteredLeftBirthday) > -1;
          }
        })
      }
      if (this.enteredLeftCorpName != null && this.enteredLeftCorpName != '') {
        arr = arr.filter(item => {
          return item.corpName.indexOf(this.enteredLeftCorpName) > -1;
        })
      }
      if (this.enteredLeftReprName != null && this.enteredLeftReprName != '') {
        arr = arr.filter(item => {
          return item.reprName.indexOf(this.enteredLeftReprName) > -1;
        })
      }
      if (this.leftKind != null && this.leftKind !== '') {
        arr = arr.filter(item => {
          if (this.leftKind === 'ALL') {
            return item;
          } else {
            return item.bizKind.code.indexOf(this.leftKind) > -1;
          }
        })
      }
      return arr;
    },
    //유형 seletBox
    leftKindChanged(val) {
      //	selectBox selected 속성값 변경
      this.leftKind = val;
      for (let i = 0; i < this.leftSeletBoxKindItem.length; i++) {
        if (this.leftSeletBoxKindItem[i].key === val) {
          this.leftSeletBoxKindItem[i].selected = true;
        } else {
          this.leftSeletBoxKindItem[i].selected = false;
        }
      }
    },
    //========== 우측 검색 filter 메서드.  ==========
    doFilterRightItems(items) {
      let arr = new Array();
      arr = items;

      if (this.enteredRightCorpRegNo != null && this.enteredRightCorpRegNo != '') {
        arr = arr.filter(item => {
          if (item.corpRegNo != null && item.corpRegNo !== '') {
            return item.corpRegNo.indexOf(this.enteredRightCorpRegNo) > -1;
          }
        })
      }
      if (this.enteredRightBirthday != null && this.enteredRightBirthday != '') {
        arr = arr.filter(item => {
          if (item.resNo != null && item.resNo !== '') {
            var str = item.resNo.substr(0, 6);
            return str.indexOf(this.enteredRightBirthday) > -1;
          }
        })
      }
      if (this.enteredRightCorpName != null && this.enteredRightCorpName != '') {
        arr = arr.filter(item => {
          return item.corpName.indexOf(this.enteredRightCorpName) > -1;
        })
      }
      if (this.enteredRightReprName != null && this.enteredRightReprName != '') {
        arr = arr.filter(item => {
          return item.reprName.indexOf(this.enteredRightReprName) > -1;
        })
      }
      if (this.rightKind != null && this.rightKind !== '') {
        arr = arr.filter(item => {
          if (this.rightKind === 'ALL') {
            return item;
          } else {
            return item.bizKind.code.indexOf(this.rightKind) > -1;
          }
        })
      }
      return arr;
    },
    //유형 seletBox
    rightKindChanged(val) {
      //	selectBox selected 속성값 변경
      this.rightKind = val;
      for (let i = 0; i < this.rightSeletBoxKindItem.length; i++) {
        if (this.rightSeletBoxKindItem[i].key === val) {
          this.rightSeletBoxKindItem[i].selected = true;
        } else {
          this.rightSeletBoxKindItem[i].selected = false;
        }
      }
    },
    //========== 기타 메서드.  ==========
    //	주민번호 필요한 스크랩핑 여부 판단
    isNeedResNoScrapYn(val) {
      return this.isNeedResNoScrap.includes(val);
    },
    //  사업자번호 format
    formatCorpRegNo(str) {
      if (str != null || str !== undefined || str !== '') {
        let originStr = str.toString();
        let formattedStr = '';

        if (originStr != null || originStr != '') {
          if (originStr.length == 10) {
            formattedStr = originStr.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
          }
        }
        return formattedStr;
      }
    },
  },
  watch: {
    parentCorpList(val) {
      this.leftDivItems = val;
      //스크랩 내역 종류 변경했을 때 우측 초기화.
      this.rightSaveItems = [];
      this.rightDivItems = [];
    },
    leftDivItems() {
      this.checkedForAdd = [];
      this.checkedForDelete = [];
    },
    rightSaveItems(val) {
      this.chooseCorpIdxList = [];
      for (var l = 0; l < val.length; l++) {
        this.chooseCorpIdxList.push(val[l].idx);
      }
    },
    chooseCorpIdxList(val) {
      this.$emit('toParent:companyIdxList', val);
    },
    agentVisible(val) {
      if (!val) {
        //유형 selectBox 초기화
        this.leftKindChanged('ALL');
        this.rightKindChanged('ALL');
      }
    },
    scrapId(val) {
      //유형 selectBox 초기화
      this.leftKindChanged('ALL');
      this.rightKindChanged('ALL');
      this.enteredLeftCorpRegNo = ''
      this.enteredLeftBirthday = ''
      this.enteredLeftCorpName = ''
      this.enteredLeftReprName = ''

      this.enteredRightCorpRegNo = ''
      this.enteredRightBirthday = ''
      this.enteredRightCorpName = ''
      this.enteredRightReprName = ''
    }
  },
  computed: {
    ...mapState(['isNeedResNoScrap']),
  },
}
</script>

<style scoped>

</style>