<template>
		<div v-show="show" style="position:absolute; z-index:9999;" class="wrap-bk">
			<div class="card-loading">
				<img src="../../images/loading.gif">
				<span>잠시만 기다려 주세요.</span>
			</div>
		</div>
</template>
<script>
import { eventHub } from '@/utils/eventhub.js'

export default {
	name: 'Loading',
	data:()=>{
		return{
			show: false,
		}
	},
	methods:{
		showLoading(){
			this.show = true;
		},
		hideLoading(){
			this.show = false;
		}
	},
	created(){
		eventHub.$on('before-request', this.showLoading);
		eventHub.$on('request-error',  this.hideLoading);
		eventHub.$on('after-response', this.hideLoading);
		eventHub.$on('response-error', this.hideLoading);
	},
	beforeDestroy(){
		eventHub.$off('before-request', this.showLoading);
		eventHub.$off('request-error',  this.hideLoading);
		eventHub.$off('after-response', this.hideLoading);
		eventHub.$off('response-error', this.hideLoading);
	}

}
</script>